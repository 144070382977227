import React from "react";
import PropTypes from "prop-types";
import { Container, Row, Col } from "shards-react";


const UnprotectedLayout = ({ children, noNavbar, noFooter }) => (
  <Container fluid className="icon-sidebar-nav">
    <Row>

      <Col className="main-content col" tag="main">
        {children}
      </Col>
    </Row>
  </Container>
);

UnprotectedLayout.propTypes = {

};

UnprotectedLayout.defaultProps = {
  noNavbar: false,
  noFooter: false
};

export default UnprotectedLayout;
