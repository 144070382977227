import React from "react";
import { Redirect } from "react-router-dom";

// Layout Types
import { DefaultLayout, HeaderNavigation, IconSidebar, UnprotectedLayout } from "./layouts";
import Login from "./views/Login";
import Register from "./views/Register";
import ForgotPassword from "./views/ForgotPassword";
import ChangePassword from "./views/ChangePassword";
import LoginSoFlow from "./views/LoginSoFlow";
import Shared from "./views/Shared";
import {Logout} from "./authentication";


const BlankIconSidebarLayout = ({ children }) => (
  <UnprotectedLayout>
    {children}
  </UnprotectedLayout>
);

export default [
  {
    path: "/",
    exact: true,
    layout: DefaultLayout,
    component: () => <Redirect to="/login" />
  },
  {
    path: "/login",
    layout: BlankIconSidebarLayout,
    component: LoginSoFlow
  },
  {
    path: "/logout",
    layout: BlankIconSidebarLayout,
    component: Logout
  },
  {
    path: "/shared/:token",
    layout: BlankIconSidebarLayout,
    component: Shared
  },
  {
    path: "/register",
    layout: BlankIconSidebarLayout,
    component: Register
  },
  {
    path: "/forgot-password",
    layout: BlankIconSidebarLayout,
    component: ForgotPassword
  },
  {
    path: "/change-password/:token",
    layout: BlankIconSidebarLayout,
    component: ChangePassword
  }
];
