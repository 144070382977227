export default function (isAdmin) {
  if(!isAdmin) {
    return [{
      title: "Customer Portal",
      items: [{
        title: 'Profile',
        to: '/user',
        htmlBefore: '<i class="material-icons">&#xE7FD;</i>',
        htmlAfter: '',
      }, {
        title: 'Devices',
        to: '/device-overview/me',
        htmlBefore: '<i class="material-icons">list</i>',
        htmlAfter: '',
      }, {
        title: 'Location Map',
        to: '/locations',
        htmlBefore: '<i class="material-icons">location_on</i>',
        htmlAfter: '',
      }],
    }
    ]
  } else {
    return [{
      title: "Admin Bereich",
      items: [{
        title: 'Scooter',
        to: '/device-overview/admin',
        htmlBefore: '<i class="material-icons">&#xE7FD;</i>',
        htmlAfter: '',
      }, {
        title: 'Customer',
        to: '/user-overview',
        htmlBefore: '<i class="material-icons">list</i>',
        htmlAfter: '',
      }, {
        title: 'Location Map',
        to: '/locations',
        htmlBefore: '<i class="material-icons">location_on</i>',
        htmlAfter: '',
      }],
    }
    ]
  }
}
