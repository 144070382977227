/* eslint-disable no-unused-vars */

import React, { PureComponent, Fragment } from 'react';
import PropTypes from 'prop-types';

import { connect } from 'react-redux';
import { withRouter } from 'react-router';
import { Redirect } from 'react-router-dom';

const mapStateToProps = state => ({
  authenticated: state.authentication.authenticated,
});

const mapDispatchToProps = dispatch => ({
});

/**
 * UnprotectedRoutes
 */
class UnprotectedRoutes extends PureComponent {
  static propTypes = {
    authenticated: PropTypes.bool.isRequired,
    history: PropTypes.shape({
      push: PropTypes.func.isRequired,
    }).isRequired,
    children: PropTypes.node,
    redirectTo: PropTypes.string,
  }

  static defaultProps = {
    authenticated: false,
    children: null,
    redirectTo: '/',
  }

  componentDidUpdate(prevProps, prevState, snapshot) {
    if (prevProps.authenticated !== this.props.authenticated && this.props.authenticated === true) {
      this.props.history.push(this.props.redirectTo);
    }
  }

  render() {
    return (<Fragment>{this.props.children}</Fragment>);
  }
}

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(UnprotectedRoutes));
