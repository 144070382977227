const serverPort = 3000;

const apiAddress = (function () {
  return "https://soflow.glazy.ai"
  let hostname = window.location.hostname.split('.');

  // if a port is used or localhost is the hostname
  const isLocalhost =
    window.location.origin.split(':') > 2 ||
    (hostname.length === 1 && hostname[0] === 'localhost');

  if (isLocalhost) {
    return 'http://'+ hostname.join('.') +':' + serverPort;
  } else {
    return "https://middleware.theftex.com"
  }

  if (hostname[0] === 'develop') {
    hostname.splice(1, 0, 'api');
  } else {
    hostname.unshift('api');
  }

  return 'https://' + hostname.join('.');
})();

export default apiAddress;
