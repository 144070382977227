import {
  FETCH_USERS, UPDATE_USER,
} from "./actions";

const initialState = {
  allUsers: [],
};

export default function user(state = initialState, action={}) {
  switch(action.type) {
    case FETCH_USERS: {
      const { user } = action;
      return {
        ...state,
        allUsers: user
      };
    }
    default: {
      return state;
    }
  }
}
