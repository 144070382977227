import Https from "../../services/api/apiClient";
import {LOGIN_USER} from "../authentication/actions";
export const FETCH_USERS = 'FETCH_USERS';
export const UPDATE_USER = 'UPDATE_USER';

export function createCustomer(customer, successCB, errorCB) {
  return function (dispatch, getState) {
    console.log('SIGNUP ', customer)
    Https.post('/api/v1/users/postSignup', {...customer})
      .then( res => {
        console.log('customer successfully created - res: ', res);
        successCB();
      })
      .catch( err => {
        const { response: {data}} = err;
        errorCB(data.error);
        console.log('Couldnt create customer - ', data)
      })
  };
}

export function updateAccount(info, successCB, errorCB) {
  return function (dispatch, getState) {
    Https.post('/api/v1/users/postUpdateProfile', {...info})
      .then( (res) => {
        console.log('account successfully updated - res: ', res);
        if(res.data.data.state == "own_change") {
          dispatch({
            type: UPDATE_USER,
            user: res.data.data.user,

          });
        }

        successCB(res.data.data);
      })
      .catch(err => {
        const { response: {data}} = err;
        errorCB(data);
        console.log('Couldnt update account - ', data)
      })
  };
}

export function fetchAllUsers(successCB, errorCB) {
  return function (dispatch, getState) {
    Https.get('/api/v1/users/')
      .then( res => {
        console.log('account successfully updated - res: ', res);
        successCB(res.data.data);
        dispatch({
          type: FETCH_USERS,
          user: res.data.data
        });
      })
      .catch(err => {
        errorCB(err);
        console.log('Couldnt not fetch fetchAllUsers - ', err)
      })
  };
}

