import React from "react";
import PropTypes from "prop-types";
import {
  Card,
  CardHeader,
  ListGroup,
  ListGroupItem,
  Row,
  Col,
  Form,
  FormGroup,
  FormInput,
  FormSelect,
  FormTextarea,
  Button,
  FormCheckbox,
  InputGroup,
  InputGroupAddon
} from "shards-react";

class ApiKey extends React.Component {

  constructor(props){
    super(props);

    this.state = {
      activated: true
    }
  }

  toggleActivation() {
    this.setState({activated: !this.state.activated});
  }

  render() {
    return (
      <Card small className="mb-4">
        <CardHeader className="border-bottom">
          <h6 className="m-0">{this.props.title}</h6>
        </CardHeader>
        <ListGroup flush>
          <ListGroupItem className="p-3">
            <div style={{paddingBottom: 10}}>
            <strong className="text d-block mb-2"><a download href={"https://middleware.theftex.com/backend/static/media/theftex_api_doc.pdf"}>Download Documentation</a> </strong>
            </div>
              <Row>
              {/*<Col>*/}
              {/*<FormCheckbox toggle small>*/}
              {/*Deaktiviert*/}
              {/*</FormCheckbox>*/}
              {/*</Col>*/}
            </Row>
            {!this.state.activated &&
              <Row>
                <Col>
                  <Button onClick={this.toggleActivation.bind(this)} size="sm" theme="success" className="mb-2 mr-1">
                    Aktivieren
                  </Button>
                </Col>
              </Row>

            }


            {this.state.activated &&
              <Row>
                <Col>
                  <Form>
                    <Row form>
                      <Col md="12" className="form-group">
                        <label htmlFor="fePassword">Your Api-Key:
                        </label>
                        <InputGroup seamless className="mb-3">
                          <FormInput value={this.props.apiKey}/>
                          <InputGroupAddon type="append">
                            <Button theme="white">Copy</Button>
                          </InputGroupAddon>
                        </InputGroup>
                      </Col>
                    </Row>
                    <div style={{display: "flex"}}>
                      {/*<div style={{paddingRight: 10}}>*/}
                        {/*<Button theme="accent">Generate New</Button>*/}
                      {/*</div>*/}
                      <Button disabled={!this.props.hasAccess} onClick={this.toggleActivation.bind(this)} theme="danger">Deactivate</Button>
                      {!this.props.hasAccess ? <span>API Access is not granted. Please contact us to upgrade your plan</span> : null}
                    </div>
                  </Form>
                </Col>
              </Row>
            }
          </ListGroupItem>
        </ListGroup>
      </Card>
    )
  }
}



ApiKey.propTypes = {
  /**
   * The component's title.
   */
  title: PropTypes.string
};

ApiKey.defaultProps = {
  title: "API Access"
};

export default ApiKey;
