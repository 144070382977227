/*global google*/
/*global MarkerClusterer*/

import React from "react"
import { Container, Row, Col,Card, CardHeader,Form,
  InputGroup,
  InputGroupAddon,
  InputGroupText,
  FormInput,
  ButtonGroup,
  FormCheckbox,
  Button } from "shards-react";
import PageTitle from "./../components/common/PageTitle";
import DatePicker from "react-datepicker";
import {deleteDevice, fetchAllDevices, updateLabel, getDeviceSharing, getDeviceDataByDate} from "../reducers/devices/actions";
import {connect} from "react-redux";
import { withRouter } from 'react-router';
import moment from "moment";
import CustomerSelect from "../components/CustomerSelect";
import EditableLabel from "../components/EditableLabel";
import {toast} from "react-toastify/index";

var geocoder;
var map;
var markers = [];
var defaultMarkers = [];
var list;


class LocationMap extends React.Component {

  constructor(props) {
    super(props)

      var {allDevices} = props;

    var allDevices = allDevices.filter(function (el) {
      return el.deviceType == "1M2M"
    });

    // TODO Fetch real devices
    this.state = {
      filteredDevices: allDevices,
      containerHeight: 500,
      clientHeight:500,
      currentDeviceDetails: null,
      currentShareLink: "https://theftex.com/location/CzvKVsx0j8kwyC1",
      currentSharingPassword: "",
      currentSharingDate: "",
      startDate: new Date(),
      shareLink: false,
      passwordSharing: false,
      sharingDueDate: false,
      dataDate: new Date(),
      filter: "",
      sidebarListHeight: "100%"
    }

    this.initMap = this.initMap.bind(this);
    this.onDeviceListElementClicked = this.onDeviceListElementClicked.bind(this);

    this.mapInitialzied= false;
    this.flightPath = null;

    this.infoWindows = [];
    this.defaultInfoWindows = [];
  }

  componentDidMount() {
    window.initMap = this.initMap;
    this.loadJS('https://maps.googleapis.com/maps/api/js?key=AIzaSyBM2m31gEoHHxq41ITLPOF_ouv_bVshui0&callback=initMap')
    if(this.container) {
      this.setState({containerHeight: this.container.clientHeight})
    }

    this.props.fetchAllDevices((data) => {
 //     console.log("get all success",data)
      var data = data.filter(function (el) {
        return el.deviceType == "1M2M"
      });
      this.setState({filteredDevices: data})
      this.addDefaultMarkers(data);
    }, (err) => {
      console.log("get all failed",err)
    })
  }

  addDefaultMarkers(allDevices) {
    if( this.mapInitialzied) {
      this.defaultInfoWindows = [];
      defaultMarkers = [];
      var bounds = new google.maps.LatLngBounds();
      for(var i = 0; i < allDevices.length; i++) {
        const currentDevice = allDevices[i];
        if(currentDevice.location) {
          const lastLat = currentDevice.location.lat;
          const lastLong = currentDevice.location.long;
          list.push([lastLat, lastLong])

          var marker = new google.maps.Marker({
            position: new google.maps.LatLng(lastLat, lastLong),
            map: map
          });

          marker.index = this.defaultInfoWindows.length;
          marker.addListener('mouseover', function() {

          });

          marker.addListener('mouseout', function() {

          });
          var detailId = "\"device-list-" + currentDevice.qrcode + "\""
          var infowindow = new google.maps.InfoWindow({
            content: "<div style='font-weight: bold'>"+currentDevice.qrcode+"</div><div>"+ moment(currentDevice.location.updatedAt).format("HH:mm") +" Uhr</div><div>Lat: "+lastLat+"</div><div>Lon: "+lastLong+"</div><div style='color: #C00000; cursor: pointer;' onclick='document.getElementById("+detailId+").click()'>Show details</div>"
          });

          this.defaultInfoWindows.push(infowindow);
          marker.addListener('click', this.onDefaultMarkerClicked.bind(this, marker));
          defaultMarkers.push(marker);
          bounds.extend(marker.position);
        }
      }

      list.forEach(function (data, index, array) {

        // var marker = new google.maps.Marker({
        //   position: new google.maps.LatLng(list[index][0], list[index][1]),
        //   map: map
        // });
        // marker.addListener('mouseover', function() {
        //
        // });
        //
        // marker.addListener('mouseout', function() {
        //
        // });
        //
        // var infowindow = new google.maps.InfoWindow({
        //   content: "<div style='font-weight: bold'>Nr: "+(x+1).toString()+"</div><div>"+ moment(data[x].timestamp).format("HH:mm") +" Uhr</div><div>Lat: "+data[x]["lat"]+"</div><div>Lon: "+data[x]["long"]+"</div>"
        // });
        //
        // this.infoWindows.push(infowindow);
        // marker.addListener('click', this.onMarkerClicked.bind(this, marker));
        // markers.push(marker);

        //bounds.extend(marker.position);
      });
      //map.fitBounds(bounds);
      var markerCluster = new MarkerClusterer(map, defaultMarkers,
        {imagePath: 'https://developers.google.com/maps/documentation/javascript/examples/markerclusterer/m'});

      if(defaultMarkers.length > 0) {
        //var latLng = markers[0].getPosition(); // returns LatLng object
        //map.setCenter(latLng);
        map.setZoom(5);
      }
    }
  }


  loadJS(src) {
    var ref = window.document.getElementsByTagName("script")[0];
    var script = window.document.createElement("script");
    script.type = "text/javascript";
    script.src = src;
    script.async = true;
    ref.parentNode.insertBefore(script, ref);
  }

  initMap() {
    map = new google.maps.Map(document.getElementById('map-canvas'), {
      mapTypeId: google.maps.MapTypeId.ROADMAP,
      disableDefaultUI: true,
      center: new google.maps.LatLng(52.520008, 13.404954), //**Change the co-ordinates as you want**
      zoom: 3,
    });

    this.mapInitialzied = true;

    list = [

    ];


  }

  removeCurrentMarkers() {
    //console.log("removeCurrentMarkers", markers);
    for(var i = 0; i < markers.length; i++) {
      markers[i].setMap(null);
    }

    for(var i = 0; i < defaultMarkers.length; i++) {
      defaultMarkers[i].setMap(null);
    }

    if(this.flightPath) {
      this.flightPath.setMap(null);
    }
  }

  renderNewMarkersByDeviceData(data) {
 //   console.log("getTodaysDeviceData", data)
    this.setState({deviceData: data})
    this.removeCurrentMarkers();
    markers = [];
    var bounds = new google.maps.LatLngBounds();
    for(var x = 0; x < data.length; x++) {
  //    console.log("marker", x, map);
      var marker = new google.maps.Marker({
        title:  (x+1).toString(),
        position: new google.maps.LatLng(parseFloat(data[x]["lat"]), parseFloat(data[x]["long"])),
        label: (x+1).toString(),
        map: map
      });
      marker.index = x;
      marker.addListener('mouseover', function() {

      });

      marker.addListener('mouseout', function() {

      });
      var infowindow = new google.maps.InfoWindow({
        content: "<div style='font-weight: bold'>Nr: "+(x+1).toString()+"</div><div>"+ moment(data[x].timestamp).format("HH:mm") +" Uhr</div><div>Lat: "+data[x]["lat"]+"</div><div>Lon: "+data[x]["long"]+"</div>"
      });

      this.infoWindows.push(infowindow);
      marker.addListener('click', this.onMarkerClicked.bind(this, marker));
      markers.push(marker);


      bounds.extend(marker.position);
    }
 //   console.log("markers", markers)
    if(markers[0]) {
      map.fitBounds(bounds);
    }
    var coordinates = []
    for(var i =0; i < markers.length; i++) {
      coordinates.push(markers[i].getPosition());
    }

    this.flightPath = new google.maps.Polyline({
      path: coordinates,
      strokeColor: "#FF0000",
      strokeOpacity: 1.0,
      strokeWeight: 2
    });

    this.flightPath.setMap(map);

  }

  onDeviceListElementClicked(index, item) {
    this.infoWindows = [];
  //  console.log("onDeviceListElementClicked", index, markers);
    this.onDetailClicked(item, index)
    if(index && index != -1 && markers[index+1]) {
      var latLng = markers[index+1].getPosition(); // returns LatLng object
      map.setCenter(latLng);
      map.setZoom(10);
    } else {
      console.log("marker not found")
    }

    this.loadDeviceData(item.qrcode)

  }

  loadDeviceData(qrCode) {
    this.props.getDeviceDataByDate(qrCode, this.state.dataDate, (data) => {
      this.renderNewMarkersByDeviceData(data);
      // var markerCluster = new MarkerClusterer(map, markers,
      //   {imagePath: 'https://developers.google.com/maps/documentation/javascript/examples/markerclusterer/m'});
    }, (err) => {
      console.log("err", err)
    })
  }


  onMarkerClicked(marker, test) {
    // console.log("onmarkerclicked", marker, marker.index, test)
    for(var i = 0; i< this.infoWindows.length; i++) {
      this.infoWindows[i].close();
    }
    this.infoWindows[marker.index].open(map, marker);

  }

  onDefaultMarkerClicked(marker, test) {
    // console.log("onmarkerclicked", marker, marker.index, test)
    for(var i = 0; i< this.defaultInfoWindows.length; i++) {
      this.defaultInfoWindows[i].close();
    }
    if(this.defaultInfoWindows[marker.index]) {
      this.defaultInfoWindows[marker.index].open(map, marker);
    }

  }


  onFilter(ev) {
    console.log("onFilter", ev.target.value);

    const {allDevices} = this.props;
    var filteredDevices = allDevices;
    if(ev.target.value.length > 0) {
       filteredDevices = allDevices.filter((item) => {
         var labelEqual = false;
        if(item.label) {
          labelEqual = item.label.toLowerCase().includes(ev.target.value.toLowerCase());
        }
        return item.qrcode.toLowerCase().includes(ev.target.value.toLowerCase()) || labelEqual
      })

      console.log("filtered Devices", filteredDevices);


    }

    this.setState({filteredDevices: filteredDevices, filter: ev.target.value})

  }


  onContainerMount(ref){
    if(ref && ref.clientHeight) {
      this.container = ref;
    }
  }

  _handleFocusOut(object, text) {
    console.log('Left editor with text: ' + text, object);
    this.props.updateLabel(object._id, text, (response) => {
      toast.success("Device label successfully updated");
    }, (err) => {
      console.log(err)
      toast.success(err.message);
    })
  }

  onDetailClicked(item, index) {
 //   console.log("onDeviceDetailClicked", index, item)
    var sharingLink = this.extractUriSharingKey(item);
    var newState = {
      currentDeviceDetails: item
    };
    console.log("hallo", newState)
    if(sharingLink.length > 0) {
      newState.sharingKey = sharingLink;
      newState.shareLink = true;
    } else {
      newState.sharingKey = "";
      newState.shareLink = false;
    }
    console.log("hallo", newState)
    this.setState(newState);
  }

  extractUriSharingKey(device) {
    try {
      return device.sharing.uriKey;
    }catch(err) {
      console.log("extractUrishariung", err);
      return "";
    }
  }

  renderDeviceDetails() {
    const {currentDeviceDetails} = this.state;
    var label = this.state.currentDeviceDetails.label ? this.state.currentDeviceDetails.label : this.state.currentDeviceDetails.qrcode
    var lastUpdated = "-";
    var lastLat = "-";
    var lastLong = "-";
    if(currentDeviceDetails.location) {
      lastUpdated = currentDeviceDetails.location.updatedAt;
      lastLat = currentDeviceDetails.location.lat.toString();
      lastLong = currentDeviceDetails.location.long.toString();
    }
    return (
      <div className={"device-detail-sidebar"}>
        <div style={{margin: 15,display: "flex", alignItems: "center"}}>

          <EditableLabel text={label}
                         labelClassName={"this-input-label"}
                         inputClassName='myInputClass'
                         inputHeight='25px'
                         inputMaxLength='50'
                         labelFontWeight='500'
                         inputFontWeight='bold'
                         onFocusOut={(text) => { this._handleFocusOut(this.state.currentDeviceDetails, text)}}
          />
          <i style={{fontSize: 20,marginLeft: 3, color: "#479F98", cursor: "pointer"}} onClick={() => {document.getElementsByClassName("this-input-label")[0].click()}} className="material-icons">&#xE254;</i>
        </div>
        {/*{this.renderDetailItem("Name", label)}*/}
        <div style={{borderBottom: "1px solid #e1e5eb"}}></div>
        {this.renderDetailItem("Last Updated", lastUpdated)}
        <div style={{borderBottom: "1px solid #e1e5eb"}}></div>
        {this.renderDetailItem("Latitude", lastLat)}
        <div style={{borderBottom: "1px solid #e1e5eb"}}></div>
        {this.renderDetailItem("Longitude", lastLong)}
        <div style={{borderBottom: "1px solid #e1e5eb"}}></div>

        <div style={{margin: 10}}>
          <label className="container">Share public link
            <input checked={this.state.shareLink} type="checkbox" onChange={this.onSharingClicked.bind(this)}  />
            <span className="checkmark"></span>
          </label>
          {this.state.shareLink &&
            <FormInput
              id="feVAT"
              placeholder="Steuernummer"
              value={window.location.origin + "/backend/#/shared/" +this.state.sharingKey}
            />


          }
          {/*{this.state.shareLink &&*/}
            {/*<label className="container">Passwortschutz*/}
              {/*<input type="checkbox" onChange={(e) => {this.setState({passwordSharing: e.target.checked})}}  />*/}
              {/*<span className="checkmark"></span>*/}
            {/*</label>*/}
          {/*}*/}
          {/*{this.state.shareLink && this.state.passwordSharing &&*/}
             {/*<input placeholder={"Wählen Sie ein Passwort für den öffentlichen Link"} type={"text"} className={"input-field"} />*/}
          {/*}*/}
          {/*{this.state.shareLink &&*/}
            {/*<label className="container">Ein Ablaufdatum setzen*/}
              {/*<input onChange={(e) => {this.setState({sharingDueDate: e.target.checked})}} type="checkbox"  />*/}
              {/*<span className="checkmark"></span>*/}
            {/*</label>*/}
          {/*}*/}
          {/*{this.state.shareLink && this.state.sharingDueDate &&*/}
            {/*<DatePicker*/}
              {/*className={"input-field"}*/}
              {/*selected={this.state.startDate}*/}
              {/*onChange={(e) => {this.setState({startDate: e})}}*/}
            {/*/>*/}
          {/*}*/}




        </div>
      </div>

    )
  }

  renderDetailItem(title, value) {
    return (
      <div style={{margin: 15,display: "flex"}}>
        <div style={{color: "gray", flex: 1}}>
          {title}
        </div>
        <div style={{flex: 1}}>
          {value ? value : "-"}
        </div>

      </div>
    )
  }

  onSharingClicked(e) {
    this.setState({shareLink: e.target.checked})
    // TODO GENERATE SHARING LINK
    this.props.getDeviceSharing(this.state.currentDeviceDetails.qrcode, (sharingKey) => {
      console.log("sharing link", sharingKey);
      this.setState({sharingKey: sharingKey})
    }, (err) => {

    })
  }

  lastUpdatedLabel(item) {
    var lastUpdated = "-";
    var lastLat = "-";
    var lastLong = "-";
    if(item.location) {
      lastUpdated = item.updatedAt;
    }

    return lastUpdated;
  }

  filterUser(user) {
    console.log("user", user);
    const {allDevices} = this.props;
    var filteredDevices = allDevices;
    if(user._id != -1) {
      filteredDevices = allDevices.filter((item) => {
        var userEqual = false;
        if(item.user) {
          userEqual = item.user._id == user._id
        }


        return userEqual
      })
    }


    console.log("filtered Devices", filteredDevices);


    this.setState({filteredDevices: filteredDevices})
  }

  onSidebarContainer(ref) {
    console.log("ref", ref)
    if(ref) {
      console.log("ref", ref.getBoundingClientRect().height)
     if(this.state.sidebarListHeight == "100%" && ref.getBoundingClientRect().height != 0 )
      this.setState({sidebarListHeight: ref.getBoundingClientRect().height})
    }
  }

  isAdmin() {
    try {
      if(this.props.user.roles.includes("admin")) {
        return true;
      }
    }catch(err) {

    }
    return false;
  }

  renderDefaultDeviceSidebar() {

    if(this.state.currentDeviceDetails != null) {
      return this.renderDeviceDetails();
    }

    return (
      <div style={{height: "100%"}}>
          <Row style={{marginLeft: 0, marginRight: 0}} className="border-bottom py-2 bg-light">
            <Col lg="12" className="mb-2 mb-lg-0">
              <Form action="POST">
                <InputGroup value={this.state.filter} onChange={this.onFilter.bind(this)} seamless size="sm">
                  <InputGroupAddon  type="prepend">
                    <InputGroupText>
                      <i className="material-icons">search</i>
                    </InputGroupText>
                  </InputGroupAddon>
                  <FormInput  value={this.state.filter} />
                </InputGroup>
              </Form>
            </Col>
          </Row>
        {this.isAdmin() ?
          <Row style={{marginLeft: 0, marginRight: 0}} className="border-bottom py-2 bg-light">
            <Col lg="12" className="mb-2 mb-lg-0">
              <Form action="POST">
                <CustomerSelect
                  onChange={(e, value) => {this.filterUser(value)}}
                  closeMenuOnSelect={true}
                  defaultElement={"All"}
                  allUsers={this.props.allUsers} />
              </Form>
            </Col>
          </Row>
        : null}

          <div ref={this.onSidebarContainer.bind(this)}   style={{overflowY: "scroll", overflowX: "hidden", height: this.state.sidebarListHeight}}>
        {this.state.filteredDevices.map((item, idx) => (
          <Row onClick={() => this.onDeviceListElementClicked(idx, item)} style={{display: "flex", alignItems: "center",borderLeft: "4px solid red",margin: 2, paddingLeft: 2, paddingTop: 5, paddingBottom: 5, borderBottom: "1px solid #e1e5eb", cursor: "pointer"}} key={idx} className="px-3 location-device-element">
            {/*<Col className="sc-stats__image">*/}
            {/*<img*/}
            {/*alt={item.title}*/}
            {/*className="border rounded"*/}
            {/*src={item.image}*/}
            {/*/>*/}
            {/*</Col>*/}

            {/*<input type={"checkbox"}/>*/}
            <div id={"device-list-"+item.qrcode} style={{display: "flex", flexDirection: "column"}}>
              <div>{item.label ? item.label : item.qrcode}</div>
              <div style={{fontSize: "0.8em", color: "gray"}}>Last updated: {this.lastUpdatedLabel(item)}</div>
            </div>
            <div style={{display: "flex", flex: 1, flexDirection: "row", justifyContent: "flex-end" }}>
              {/*<div onClick={(e) => {this.onDetailClicked(item, idx)}} style={{margin: 2}}>Details</div>*/}
            </div>

          </Row>
        ))}
      </div>
      </div>
    )
  }

  onBack() {
    this.setState({currentDeviceDetails : null, deviceData: []})
    this.removeCurrentMarkers();
    setTimeout(() => {
      this.addDefaultMarkers(this.state.filteredDevices);
    }, 1000)

  }

  renderSidebarHeader() {
    if(this.state.currentDeviceDetails != null) {
      return (
        <div style={{display: "flex", alignItems: "center"}}>
          <i onClick={this.onBack.bind(this)}  style={{fontSize: 20, marginRight: 5, cursor: "pointer"}} className={"material-icons"}>arrow_back</i>
          <h6 className="m-0">{this.state.currentDeviceDetails != null ? "Device Details" : "Your Devices"}</h6>
        </div>
      )
    } else {
      return <h6 className="m-0">{this.state.currentDeviceDetails != null ? "Device Details" : "Your Devices"}</h6>;
    }
  }

  onDateChange(e) {
    this.state.dataDate = e;
    this.setState({dataDate: e});
    this.loadDeviceData(this.state.currentDeviceDetails.qrcode);
  }


  renderHeaderSelection() {
    var noDataAvailableLabel = null;
    if(this.state.deviceData && this.state.deviceData.length == 0) {
      noDataAvailableLabel = <span style={{color: "red", marginRight: 5}}>No data available. Please select another date</span>
    }
    if(this.state.currentDeviceDetails) {
      var label = this.state.currentDeviceDetails.label ? this.state.currentDeviceDetails.label : this.state.currentDeviceDetails.qrcode;

        return (
        <div style={{style: "flex", alignItems: "center"}} >
          {noDataAvailableLabel}
          <Button style={{marginRight: 5}} onClick={() => {this.state.dataDate = new Date(); this.setState(this.state); this.loadDeviceData(this.state.currentDeviceDetails.qrcode);}}>Today</Button>
          <DatePicker
            className={"input-field"}
            selected={this.state.dataDate}
            onChange={this.onDateChange.bind(this)}
          />
        </div>

      )
    } else {
      return null;
    }

  }


  render() {
    return (
      <div className={"root-location-map-container"} style={{display: "flex"}}>
        <Container style={{flex: 8, height: "99%"}} fluid className="main-content-container px-4">
          {/* Page Header */}
          {/*<Row noGutters className="page-header py-4">*/}
          {/*<PageTitle title="Device Locations" subtitle="Dashboard" className="text-sm-left mb-3" />*/}
          {/*</Row>*/}
          <div style={{paddingTop: "1.5rem"}}  className={"row"} ref={this.onContainerMount.bind(this)}>

            <div className={window.innerWidth < 1000 ? "col-md-12" : "col-md-9"} style={{height: "100%"}} >
              <Card style={{height: "100%"}} className="mb-4">
                <CardHeader className="border-bottom">
                  <div style={{display: "flex", flexDirection: "row", alignItems: "center"}}>
                  <h6 style={{flex: 1}} className="m-0">Location Map</h6>
                  {this.renderHeaderSelection()}
                  </div>

                </CardHeader>
                <div style={{minHeight: this.state.containerHeight}} id="map-canvas">test</div>
              </Card>
            </div>
          </div>

        </Container>

        <div className="device-side-bar" style={{minWidth: 280, width: "20%",position: "fixed", right:0 ,height: "100%"}}>
            <div className={"location-device-nav"} style={{height: '100%'}}>
              <CardHeader className="border-bottom">
                {this.renderSidebarHeader()}
              </CardHeader>
              {this.renderDefaultDeviceSidebar()}
            </div>
        </div>

      </div>
    )
  }
}





const mapStateToProps = state => ({
  allDevices: state.device.allDevices,
  allUsers: state.user.allUsers,
  user: state.authentication.user
});

const mapDispatchToProps = dispatch => ({
  fetchAllDevices: (successCb, errorCb) => dispatch(fetchAllDevices(successCb, errorCb)),
  updateLabel: (id, label, successCb, errorCb) => dispatch(updateLabel(id, label, successCb, errorCb)),
  getDeviceSharing: (qrcode, successCb, errorCb) => dispatch(getDeviceSharing(qrcode, successCb, errorCb)),
  getDeviceDataByDate: (qrcode, date, successCb, errorCb) => dispatch(getDeviceDataByDate(qrcode, date, successCb, errorCb)),
});
export default withRouter(connect(mapStateToProps, mapDispatchToProps)(LocationMap));
