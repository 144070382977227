import Https from "../../services/api/apiClient";

export const LOGIN_USER = 'LOGIN_USER';
export const LOGOUT_USER = 'LOGOUT_USER';
export const REGISTER_USER = 'REGISTER_USER';

export function loginUser(email, password, successCB, errorCB) {
  return function (dispatch, getState) {

    Https.post('/api/v1/users/login', {email, password})
      .then( res => {
        console.log('User successfully logged in - res: ', res);

        const { data: {token, user}} = res.data;

        dispatch({
          type: LOGIN_USER,
          token,
          user,
        });
        successCB();
      })
      .catch(err => {
        const { response: {data}} = err;

        errorCB(data);
        console.log('Couldnt login user - ', data)
      })
  };
}

export function logout( successCB, errorCB) {
  return function (dispatch, getState) {

    Https.get('/api/v1/users/logout')
      .then( (res) => {
        console.log('User successfully logged in - res: ', res);

        dispatch(logoutUser());
        if(successCB) successCB();
      })
      .catch((err) => {


        if(errorCB)errorCB();
        console.log('Couldnt login user - ')
      })
  };
}

export function forgetPW(email, successCB, errorCB) {
  return function (dispatch, getState) {

    Https.post('/api/v1/users/postForgot', {email})
      .then( res => {
        console.log('User successfully logged in - res: ', res);
        if(successCB) successCB();
      })
      .catch(err => {
        const { response: {data}} = err;

        errorCB(data);
        console.log('Couldnt login user - ', data)
      })
  };
}

export function changePW(password,confirmPassword,token,  successCB, errorCB) {
  return function (dispatch, getState) {

    Https.post('/api/v1/users/setNewPassword', {password, token, confirmPassword })
      .then( res => {
        console.log('User successfully logged in - res: ', res);
        if(successCB) successCB();
      })
      .catch(err => {
        console.log('Couldnt login user - ', err)
        const { response: {data}} = err;

        errorCB(data);

      })
  };
}



export function logoutUser() {
  return {
    type: LOGOUT_USER
  }
}
