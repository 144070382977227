import React from "react";
import ReactTable from "react-table";
import FuzzySearch from "fuzzy-search";
import dateFormat from "dateformat";
import {
  Container,
  Row,
  Col,
  ButtonGroup,
  Button,
  Card,
  CardHeader,
  CardBody,
  FormSelect,
  InputGroup,
  InputGroupAddon,
  InputGroupText,
  FormInput,
  Dropdown,
  DropdownToggle,
  DropdownMenu,
  DropdownItem
} from "shards-react";
import axios from 'axios';
import PageTitle from "../components/common/PageTitle";
import apiAddress from "../services/api/apiAddress"
import getAdminDummyData from "../data/admin-device-sample-data";
import getUserDummyData from "../data/user-device-sample-data";
import Select from 'react-select';
import EditableLabel from '../components/EditableLabel';
import {fetchAllDevices, updateLabel, deleteDevice, bulkAssignment} from "../reducers/devices/actions";
import {fetchAllUsers} from "../reducers/user/actions";
import { withRouter } from 'react-router';
import { connect } from 'react-redux';
import {toast} from "react-toastify/index";
import { confirmAlert } from 'react-confirm-alert'; // Import
import 'react-confirm-alert/src/react-confirm-alert.css';
import CustomerSelect from "../components/CustomerSelect"; // Import css

class DeviceOverview extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      pageSizeOptions: [5, 10, 15, 20, 25, 30, "All"],
      pageSize: 10,
      allDevices: [],
      isAdmin: false,
      selectedDevices: [],
      searchTerm: ""
    };

    this.searcher = null;

    this.getStatusClass = this.getStatusClass.bind(this);
    this.handlePageSizeChange = this.handlePageSizeChange.bind(this);
    this.handleFilterSearch = this.handleFilterSearch.bind(this);
    this.handleItemEdit = this.handleItemEdit.bind(this);
    this.handleItemDelete = this.handleItemDelete.bind(this);
    this.handleItemConfirm = this.handleItemConfirm.bind(this);
    this.handleItemViewDetails = this.handleItemViewDetails.bind(this);
    this._handleFocus = this._handleFocus.bind(this);
    this._handleFocusOut = this._handleFocusOut.bind(this);
    this.fireDeleteBox = this.fireDeleteBox.bind(this);
    this.onAssign = this.onAssign.bind(this);
  }

  componentWillMount() {
    const { scope } = this.props.match.params;

    console.log("SCOPE OF DEVICE OVERVIEW", scope, this.props.match.params);

    if(scope == "admin") {
      this.state.isAdmin = true;
    }



    const allDevices = this.props.allDevices;

    this.setState({
      ...this.state,

      allDevices
    });

    // Initialize the fuzzy searcher.
    this.searcher = new FuzzySearch(allDevices, ["user.company", "qrcode"], {
      caseSensitive: false
    });

    this.fetchTableData();

  }


  /**
   * Returns the appropriate status class for the `Status` column.
   */
  getStatusClass(status) {
    const statusMap = {
      Cancelled: "danger",
      Complete: "success",
      Pending: "warning"
    };

    return `text-${statusMap[status]}`;
  }

  fetchTableData() {
    this.props.fetchAllDevices((data) => {
      console.log("get all success",data)
      this.searcher = new FuzzySearch(data, ["user.company", "qrcode"], {
        caseSensitive: false
      });

    }, (err) => {
      console.log("get all failed",err)
    })

    this.props.fetchAllUsers((data) => {
     // console.log("fetchAllUsers Success", data)

    }, (err)=> {
      console.log("fetchAllUsers Errir ", err)
    })
  }

  /**
   * Handles the page size change event.
   */
  handlePageSizeChange(e) {
    var val = e.target.value;
    if(val == "All") val = this.props.allDevices.length;
    this.setState({
      ...this.state,
      pageSize: val
    });
  }

  /**
   * Handles the global search.
   */
  handleFilterSearch(e) {

    this.setState({
      ...this.state,
      searchTerm: e.target.value,
      allDevices: this.searcher.search(e.target.value)
    });
  }

  /**
   * Mock method for editing transactions.
   */
  handleItemEdit(row) {
    // alert(`Editing transaction "${row.original._id}"!`);
    var route = {
      pathname: '/create-update-device/'+row.original._id,
      search: '',
      state: { device: row.original }
    }
    this.props.history.push(route);

  }

  fireDeleteBox(id) {
    this.props.deleteDevice(id, (success) => {
      this.fetchTableData();
    }, (err) => {
      // TODO error
    });
  }

  /**
   * Mock method for deleting transactions.
   */
  handleItemDelete(row) {
    // alert(`Deleting transaction "${row.original._id}"!`);
    confirmAlert({
      title: 'Confirm to delete',
      message: 'Are you sure to delete the box?',
      buttons: [
        {
          label: 'Yes',
          onClick: () => this.fireDeleteBox(row.original._id)
        },
        {
          label: 'No',
          onClick: () => {}
        }
      ]
    });
  }
  /**
   * Mock method for confirming transactions.
   */
  handleItemConfirm(row) {
    alert(`Confirming transaction "${row.original.id}"!`);
  }

  /**
   * Mock method for confirming transactions.
   */
  handleItemViewDetails(row) {
    alert(`Viewing details for "${row.original.id}"!`);
  }

  getAdminActions(row) {
    /*
      Admin
    */

    return (
      <ButtonGroup size="sm" className="d-table mx-auto">
        {/*<Button*/}
          {/*theme="white"*/}
          {/*onClick={() => this.handleItemViewDetails(row)}*/}
        {/*>*/}
          {/*<i className="material-icons">&#xE870;</i>*/}
        {/*</Button>*/}
        <Button theme="white" onClick={() => this.handleItemEdit(row)}>
          <i className="material-icons">&#xE254;</i>
        </Button>
        <Button theme="white" onClick={() => this.handleItemDelete(row)}>
          <i className="material-icons">&#xE872;</i>
        </Button>
      </ButtonGroup>
    )
  }
  toggle(which) {
    const newState = { ...this.state };
    newState[which] = !this.state[which];
    this.setState(newState);
  }

  onCreate() {
    this.props.history.push('/create-update-device/new');
  }

  renderAddButton() {
    if(this.state.isAdmin) {
      // return (
      //   <div style={{paddingBottom: 10, display: "flex", flex: 1, justifyContent: "flex-end"}}>
      //     <Button onClick={this.onCreate.bind(this)}>New Device</Button>
      //   </div>
      // )
    }
    return null;
  }

  onAssign() {
    this.props.bulkAssignment(this.state.selectedDevices, this.state.selectecUser, (success) => {
      this.fetchTableData();
      this.setState({selectedDevices: []})
    }, (error)=> {
      alert("err")
    })
  }

  onTextFieldChanged(id, user) {

    console.log("user", user)
    this.state.selectecUser = user;
    this.setState(this.state)
  }

  renderBulkAktionButton() {
    var {selectedDevices} = this.state;
    if(selectedDevices.length > 0) {
      return (
        <div style={{paddingBottom: 10, display: "flex", flex: 1, justifyContent: "flex-start"}}>
          <CustomerSelect
            onChange={(e, value) => {this.onTextFieldChanged("user", value)}}
            closeMenuOnSelect={true}
            allUsers={this.props.allUsers}
          />
          <Button style={{marginLeft: 4}} onClick={this.onAssign.bind(this)}>Assign</Button>
        </div>
        )

    }
  }

  _handleFocus(text) {
    console.log('Focused with text: ' + text);
  }

  _handleFocusOut(object, text) {
    console.log('Left editor with text: ' + text, object);
    this.props.updateLabel(object._id, text, (response) => {
      toast.success("Device label successfully updated");
    }, (err) => {
      console.log(err)
      toast.success(err.message);
    })
  }

  renderInlineEdit(row) {

    var text = row.original.label;
    if(!text) text = "-";
    //console.log("render Inline edit", row, text)
    return (
      <div style={{display: "flex", alignItems: "center"}}>

      <EditableLabel text={text}
           labelClassName={row.original._id+ "-input-label"}
           inputClassName='myInputClass'
           inputHeight='25px'
           inputMaxLength='50'
           labelFontWeight='bold'
           inputFontWeight='bold'
           onFocus={this._handleFocus}
           onFocusOut={(text) => { this._handleFocusOut(row.original, text)}}
      />
        <i style={{fontSize: 20,marginLeft: 3, color: "#479F98", cursor: "pointer"}} onClick={() => {document.getElementsByClassName(row.original._id+ "-input-label")[0].click()}} className="material-icons">&#xE254;</i>
      </div>
    )
  }

  handleOnSelectRow(element) {
    var alreadyAdded = -1;
    var {selectedDevices} = this.state;
    for(var i = 0; i< selectedDevices.length; i++) {
      if(selectedDevices[i] == element._id) {
        alreadyAdded = i;
      }
    }

    if(alreadyAdded != -1) {
      selectedDevices.splice(alreadyAdded, 1);
    } else {
      selectedDevices.push(element._id);
    }
    this.setState(this.state);
    console.log("state", this.state.selectedDevices);
  }

  isChecked(element) {
    var {selectedDevices} = this.state;
    for(var i = 0; i< selectedDevices.length; i++) {
      if(selectedDevices[i] == element._id) {
        return true;
      }
    }

    return false;
  }

  onChangeFileHandler=event=>{

    console.log(event.target.files[0])
    const data = new FormData()
    data.append('file', event.target.files[0])
    axios.post(apiAddress +"/api/v1/scooter/csvUpload", data, {
      // receive two    parameter endpoint url ,form data
    }).then(()=> {
      alert("success")
    })

  }



  render() {
    const { pageSize, pageSizeOptions, searchTerm } = this.state;

    var filteredDataSet = this.state.allDevices;
    var allDevices = this.props.allDevices;

    if(allDevices.length != filteredDataSet && searchTerm.length > 0) {
      allDevices = filteredDataSet;
    }

    const adminTableColums = [
      {
        Header: "",
        accessor: "createdAt",
        maxWidth: 37,
        className: "text-center",
        Filter: ()=> null,
        Cell: row =>
        {
          return <input checked={this.isChecked(row.original)} onChange={() => this.handleOnSelectRow(row.original)} type={"checkbox"} />
        }
      },
      {
        Header: "Device ID",
        accessor: "deviceId",
        maxWidth: 300,
        className: "text-center",
        Filter: ()=> null,
      },
      {
        Header: "Verification Token",
        accessor: "verificationToken",
        maxWidth: 300,
        className: "text-center"
      },
      /*{
        Header: "Customer",
        accessor: "customer",
        filterMethod: (filter, row) => {
          if (filter.value === "all") {
            return true;
          }
          if (filter.value === "true") {
            //console.log("filter", row, filter)
            return !row._original.user
          }
          return true;
        },
        maxWidth: 200,
        className: "text-center",
        Cell: row => {
          if(row.original.user && row.original.user.company) {
            return row.original.user.company;
          } else if(row.original.user){
              const {forename, surname} =  row.original.user;
              var nameString = "";
              if(forename) nameString += forename;
              if(surname) nameString = nameString + " " + surname;
              return nameString;
          }
          return "";
        },
      Filter: ({ filter, onChange }) =>
      <select
        onChange={event => onChange(event.target.value)}
        style={{ width: "100%" }}
        value={filter ? filter.value : "all"}
      >
        <option value="all">All</option>
        <option value="true">Unassigned</option>
      </select>

      },
      {
        Header: "Label",
        accessor: "label",
        minWidth: 200,
        Filter: ()=> null,
        className: "text-center",
        Cell: row => this.renderInlineEdit(row)
      },*/
      {
        Header: "Date",
        Filter: ()=> null,
        accessor: "createdAt",
        className: "text-center",
        Cell: row =>
          dateFormat(new Date(row.original.createdAt), "dd.mm.yyyy")
      },
      {
        Header: "Actions",
        Filter: ()=> null,
        accessor: "actions",
        sortable: false,
        Cell: row => this.getAdminActions(row)
      }
    ]
    const userTableColumns = [
      {
        Header: "QR-Code",
        accessor: "qrcode",
        className: "text-center"
      },
      {
        Header: "Label",
        accessor: "label",
        className: "text-center",
        Cell: row => this.renderInlineEdit(row)
      },
      {
        Header: "Date",
        accessor: "createdAt",
        className: "text-center",
        Cell: row =>
          dateFormat(new Date(row.original.createdAt), "dd.mm.yyyy")
      },
      // {
      //   Header: "Actions",
      //   accessor: "actions",
      //   maxWidth: 300,
      //   minWidth: 180,
      //   sortable: false,
      //   Cell: row => this.getAdminActions(row)
      // }
    ]
    const tableColumns = this.state.isAdmin ? adminTableColums : userTableColumns;

    return (
      <Container fluid className="main-content-container px-4 pb-4">
        <Row noGutters className="page-header py-4">
          <PageTitle title={this.state.isAdmin ? "All Devices" : "Your Devices"} subtitle="Overview" className="text-sm-left mb-3" />
          {/*<Col sm="4" className="d-flex ml-auto my-auto">*/}
            {/*<RangeDatePicker className="justify-content-end" />*/}
          {/*</Col>*/}
        </Row>
        <div style={{marginBottom: 10}}>
          Bulk Upload: <input accept=".csv" type="file" name="file" onChange={this.onChangeFileHandler.bind(this)}/>
        </div>


        <div style={{display: "flex"}}>

          {
            this.renderBulkAktionButton()
          }
        {
          this.renderAddButton()
        }

        </div>
        <Card className="p-0">
          <CardHeader className="p-0">
            <Container fluid className="file-manager__filters border-bottom">
              <Row>
                {/* Filters :: Page Size */}
                <Col className="file-manager__filters__rows d-flex" md="6">
                  <span>Show</span>
                  <FormSelect
                    size="sm"
                    value={this.state.pageSize}
                    onChange={this.handlePageSizeChange}
                  >
                    {pageSizeOptions.map((size, idx) => (
                      <option key={idx} value={size}>
                        {size} rows
                      </option>
                    ))}
                  </FormSelect>
                </Col>

                {/*<Col>*/}
                  {/*<Select*/}
                    {/*onChange={(e) => {this.onTextFieldChanged("user", e.value)}}*/}
                    {/*closeMenuOnSelect={true}*/}
                    {/*options={this.userSelectOptions}*/}
                  {/*/>*/}
                {/*</Col>*/}

                {/* Filters :: Search */}
                <Col className="file-manager__filters__search d-flex" md="6">
                  <InputGroup seamless size="sm" className="ml-auto">
                    <InputGroupAddon type="prepend">
                      <InputGroupText>
                        <i className="material-icons">search</i>
                      </InputGroupText>
                    </InputGroupAddon>
                    <FormInput onChange={this.handleFilterSearch} />
                  </InputGroup>
                </Col>
              </Row>
            </Container>
          </CardHeader>
          <CardBody className="p-0">
            <div className="">
              <ReactTable
                filterable
                columns={tableColumns}
                data={allDevices}
                pageSize={pageSize}
                showPageSizeOptions={false}
                resizable={false}
              />
            </div>
          </CardBody>
        </Card>

      </Container>
    );
  }
}


const mapStateToProps = state => ({
  allDevices: state.device.allDevices,
  allUsers: state.user.allUsers,
});

const mapDispatchToProps = dispatch => ({
  fetchAllDevices: (successCb, errorCb) => dispatch(fetchAllDevices(successCb, errorCb)),
  fetchAllUsers: (successCb, errorCb) => dispatch(fetchAllUsers(successCb, errorCb)),
  updateLabel: (id, label, successCb, errorCb) => dispatch(updateLabel(id, label, successCb, errorCb)),
  deleteDevice: (id, successCb, errorCb) => dispatch(deleteDevice(id, successCb, errorCb)),
  bulkAssignment: (ids, user, successCb, errorCb) => dispatch(bulkAssignment(ids, user, successCb, errorCb)),
});
export default withRouter(connect(mapStateToProps, mapDispatchToProps)(DeviceOverview));
