import Https from "../../services/api/apiClient";

export const FETCH_DEVICES = 'FETCH_DEVICES';


export function createDevice(device, successCB, errorCB) {
  return function (dispatch, getState) {
    Https.post('/api/v1/scooter/addScooterMapping', {...device})
      .then( res => {
        console.log('device successfully created - res: ', res);
        successCB();
      })
      .catch( err => {
        const { response: {data}} = err;
        errorCB(data.error);
        console.log('Couldnt create device - ', data)
      })
  };
}

export function updateDevice(device, successCB, errorCB) {
  return function (dispatch, getState) {
    Https.put('/api/v1/scooter/', {...device})
      .then( res => {
        console.log('device successfully updated - res: ', res);
        successCB();
      })
      .catch( err => {
        const { response: {data}} = err;
        errorCB(data.error);
        console.log('Couldnt updated device - ', data)
      })
  };
}

export function bulkAssignment(userids, user, successCB, errorCB) {
  return function (dispatch, getState) {
    Https.post('/api/v1/scooter/assignment/bulk', {user: user, ids: userids})
      .then( res => {
        console.log('device successfully updated - res: ', res);
        successCB();
      })
      .catch( (err) => {
        if(errorCB) errorCB(err);

        console.log('Couldnt updated device - ', err)
      })
  };
}


export function fetchAllDevices(successCB, errorCB) {
  return function (dispatch, getState) {
    Https.get('/api/v1/scooter/')
      .then( (res) => {
        try {
          console.log('get all devices successfully fetched - res: ', res);
          successCB(res.data.data);
          dispatch({
            type: FETCH_DEVICES,
            devices: res.data.data
          });
        }catch(err) {
          console.log("err", err)
        }

      })
      .catch( (err) => {
        try {
          const { response: {data}} = err;
          errorCB(data.error);
          console.log('Couldnt fetch devices - ', data)
        }catch(err) {
          console.log("err", err)
        }

      })
  };
}


export function updateLabel(id, label, successCB, errorCB) {
  return function (dispatch, getState) {
    Https.put('/api/v1/scooter/label', {id: id, label: label})
      .then( res => {
        console.log('device label successfully updated - res: ', res);
        successCB();
      })
      .catch( err => {
        const { response: {data}} = err;
        errorCB(data.error);
        console.log('Couldnt updated device label - ', data)
      })
  };
}

export function deleteDevice(id, successCB, errorCB) {
  return function (dispatch, getState) {
    Https.delete('/api/v1/scooter', {id: id})
      .then( res => {
        console.log('device successfully deleted - res: ', res);
        if(successCB)successCB();
      })
      .catch( err => {
        if(errorCB)errorCB();
        console.log('Couldnt delete device - ' )
      })
  };
}



export function getDeviceDataBySharingToken(token, date,password, successCB, errorCB) {
  return function (dispatch, getState) {
    Https.get('/api/v1/devicedata/sharing/'+token + "?date="+ date.getTime().toString())
      .then( res => {
        console.log('device sharing successfully created - res: ', res);
        if(successCB)successCB(res.data.data);
      })
      .catch( err => {
        if(errorCB)errorCB();
        console.log('Couldnt get delete sharing - ' )
      })
  };
}

export function getDeviceSharing(qrcode, successCB, errorCB) {
  return function (dispatch, getState) {
    Https.get('/api/v1/scooter/sharing/'+qrcode)
      .then( res => {
        console.log('device sharing successfully created - res: ', res);
        if(successCB)successCB(res.data.data);
      })
      .catch( err => {
        if(errorCB)errorCB();
        console.log('Couldnt get delete sharing - ' )
      })
  };
}

export function getDeviceDataByDate(qrcode,date, successCB, errorCB) {
  return function (dispatch, getState) {
    Https.get('/api/v1/devicedata/'+qrcode + "?date="+ date.getTime().toString())
      .then( (res) => {
        console.log('device data successfully received - res: ', res);
        if(successCB)successCB(res.data.data);
      })
      .catch( (err) => {
        if(errorCB)errorCB();
        console.log('Couldnt get device data - ' )
      })
  };
}




