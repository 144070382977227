import React from "react";
import PropTypes from "prop-types";
import classNames from "classnames";
import { Col } from "shards-react";

import SidebarMainNavbar from "./SidebarMainNavbar";
import SidebarSearch from "./SidebarSearch";
import SidebarNavItems from "./SidebarNavItems";
import { withRouter } from 'react-router';

import { Store } from "../../../flux";
import {deleteDevice, fetchAllDevices, updateLabel} from "../../../reducers/devices/actions";
import {connect} from "react-redux";

class MainSidebar extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      menuVisible: false,
      sidebarNavItems: props.user && props.user.roles ? Store.getSidebarItems(props.user.roles.includes("admin")) : []
    };

    this.onChange = this.onChange.bind(this);
    this.receiveMessage = this.receiveMessage.bind(this);
  }

  componentWillMount() {
    Store.addChangeListener(this.onChange);
    window.addEventListener("message", this.receiveMessage, false);
  }

  componentWillUnmount() {
    window.addEventListener("message", this.receiveMessage, false);
    Store.removeChangeListener(this.onChange);
  }

  receiveMessage(evt) {
    if(evt.data.type == "toggleMenu") {
      this.setState({
        menuVisible: !this.state.menuVisible
      })
    }
  }

  onChange() {
    this.setState({
      ...this.state,
     // menuVisible: Store.getMenuState(),
      sidebarNavItems: this.props.user && this.props.user.roles ? Store.getSidebarItems(this.props.user.roles.includes("admin")) : []
    });
  }

  render() {
    const classes = classNames(
      "main-sidebar",
      "px-0",
      "col-12",
      this.state.menuVisible && "open"
    );

    return (
      <Col
        tag="aside"
        className={classes}
        lg={{ size: 2 }}
        md={{ size: 3 }}
      >
        <SidebarMainNavbar hideLogoText={this.props.hideLogoText} />
        <SidebarSearch />
        <SidebarNavItems />
      </Col>
    );
  }
}

MainSidebar.propTypes = {
  /**
   * Whether to hide the logo text, or not.
   */
  hideLogoText: PropTypes.bool
};

MainSidebar.defaultProps = {
  hideLogoText: false
};


const mapStateToProps = state => ({
  user: state.authentication.user
});

const mapDispatchToProps = dispatch => ({

});
export default withRouter(connect(mapStateToProps, mapDispatchToProps)(MainSidebar));
