import {
  Button,
  Card,
  CardBody,
  CardFooter,
  Col,
  Container,
  Form,
  FormCheckbox,
  FormGroup,
  FormInput,
  Row
} from "shards-react";
import {Link} from "react-router-dom";
import React, { Component } from "react";
import { connect } from 'react-redux';
import {loginUser} from "../reducers/authentication/actions";
import { withRouter } from 'react-router';
import {toast} from "react-toastify";
import 'react-toastify/dist/ReactToastify.css';

class LoginSoFlow extends Component {
  constructor(props) {
    super(props);

    this.state = {
        email: 'copf@adrodev.de',
        password: '6efrQnqqRRa7d4ymHvSBFTrZXz'
    };

  }

  componentDidMount() {
    if (this.props.authenticated) {
      this.props.history.push('/locations');
    }
  }

  onLogin = (e) => {
    e.preventDefault();
    const { email, password } = this.state;

    this.props.login(email, password,
      () => {
        this.props.history.push('/user');
      },
      (error) => {
        let message = 'Login failed.';
        if(error.metadata.statusCode === 401) {
            message += ' User credentials are invalid.'
        }
        toast.error(message);
        console.log('Login error: ', error)
      });
  };

  render() {
    return (
      <Container fluid className="main-content-container h-100 px-4">
        <Row noGutters className="h-100">
          <Col lg="3" md="5" className="auth-form mx-auto my-auto">
            <Card>
              <CardBody>
                {/* Logo */}
                <img
                  className="auth-form__logo d-table mx-auto mb-3"
                  src={require("../assets/images/logo_wide.png")}
                  style={{maxWidth: '9rem'}}
                  alt="Shards Dashboards - Login Template"
                />

                {/* Title */}
                <h5 className="auth-form__title text-center mb-4">
                  Login
                </h5>

                {/* Form Fields */}
                <Form onSubmit={this.onLogin}>
                  <FormGroup>
                    <label htmlFor="exampleInputEmail1">Email address</label>
                    <FormInput
                      type="email"
                      id="exampleInputEmail1"
                      placeholder="Enter email"
                      autoComplete="email"
                      onChange={e => this.setState({email: e.target.value})}
                    />
                  </FormGroup>
                  <FormGroup>
                    <label htmlFor="exampleInputPassword1">Password</label>
                    <FormInput
                      type="password"
                      id="exampleInputPassword1"
                      placeholder="Password"
                      autoComplete="current-password"
                      onChange={e => this.setState({password: e.target.value})}
                    />
                  </FormGroup>
                  {/*<FormGroup>*/}
                    {/*<FormCheckbox>Remember me for 30 days.</FormCheckbox>*/}
                  {/*</FormGroup>*/}
                  <Button
                    pill
                    theme="accent"
                    className="d-table mx-auto"
                    type="submit"
                  >
                    Login
                  </Button>
                </Form>
              </CardBody>

              {/* Social Icons */}
              {/*<CardFooter>*/}
                {/*<ul className="auth-form__social-icons d-table mx-auto">*/}
                  {/*<li>*/}
                    {/*<a href="#">*/}
                      {/*<i className="fab fa-facebook-f" />*/}
                    {/*</a>*/}
                  {/*</li>*/}
                  {/*<li>*/}
                    {/*<a href="#">*/}
                      {/*<i className="fab fa-twitter" />*/}
                    {/*</a>*/}
                  {/*</li>*/}
                  {/*<li>*/}
                    {/*<a href="#">*/}
                      {/*<i className="fab fa-github" />*/}
                    {/*</a>*/}
                  {/*</li>*/}
                  {/*<li>*/}
                    {/*<a href="#">*/}
                      {/*<i className="fab fa-google-plus-g" />*/}
                    {/*</a>*/}
                  {/*</li>*/}
                {/*</ul>*/}
              {/*</CardFooter>*/}
            </Card>

            {/* Meta Details */}
            <div className="auth-form__meta d-flex mt-4">
              <Link to="/forgot-password">Forgot your password?</Link>
              {/*<Link to="/register" className="ml-auto">*/}
                {/*Create a new account?*/}
              {/*</Link>*/}
            </div>
          </Col>
        </Row>
      </Container>
    );
  }

}
const mapStateToProps = state => ({
  authenticated: state.authentication.authenticated,
});

const mapDispatchToProps = dispatch => ({
  login: (email, password, successCb, errorCb) => dispatch(loginUser(email, password, successCb, errorCb)),
});
export default withRouter(connect(mapStateToProps, mapDispatchToProps)(LoginSoFlow));
