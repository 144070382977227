import React from "react";
import PropTypes from "prop-types";
import {
  Card,
  CardHeader,
  ListGroup,
  ListGroupItem,
  Row,
  Col,
  Form,
  FormGroup,
  FormInput,
  FormSelect,
  FormTextarea,
  Button
} from "shards-react";
import {loginUser} from "../../reducers/authentication/actions";
import {connect} from "react-redux";
import {createCustomer, updateAccount} from "../../reducers/user/actions";
import {toast} from "react-toastify";
import 'react-toastify/dist/ReactToastify.css';
import {withRouter} from "react-router-dom";


class UserAccountDetails extends React.Component {

  constructor(props) {
    super(props);

    this.state = {
      inputs: {
        company: props.user && props.user.company  ? props.user.company : '',
        vat: props.user && props.user.vat ? props.user.vat : '',
        forename: props.user && props.user.forename ? props.user.forename : '',
        surname: props.user && props.user.surname  ? props.user.surname : '',
        address: props.user && props.user.address ? props.user.address : {},
        email: props.user && props.user.email ? props.user.email : '',
        permissions: props.user && props.user.permissions ? props.user.permissions : {},
      }
    };

    this.onTextFieldChanged = this.onTextFieldChanged.bind(this);
  }

  componentWillReceiveProps(props) {
    this.state = {
      inputs: {
        company: props.user && props.user.company  ? props.user.company : '',
        vat: props.user && props.user.vat ? props.user.vat : '',
        forename: props.user && props.user.forename ? props.user.forename : '',
        surname: props.user && props.user.surname  ? props.user.surname : '',
        address: props.user && props.user.address ? props.user.address : {},
        email: props.user && props.user.email ? props.user.email : '',
        permissions: props.user && props.user.permissions ? props.user.permissions : {},
      }
    };
    this.setState(props)
  }

  onTextFieldChanged(key, value) {
    const { inputs } = this.state;
    inputs[key] = value;
    this.setState({inputs})
  }

  onAddressChanged(key, value) {
      const { inputs } = this.state;
      inputs.address[key] = value;
      this.setState({inputs});
  }

  onCreateAccount = () => {
    const { inputs } = this.state;

    this.props.createCustomer(inputs,
      () => {
        toast.success('Customer successfully created!');
        this.props.history.goBack();
      },
      (error) => {
        toast.error(error.message);
        console.log('Failed to update profile ', error)
      });
  };

  onUpdateAccount = () => {
    const { inputs } = this.state;
    var body = Object.assign({}, inputs, {id: this.props.user._id})
    this.props.updateAccount(body,
      (data) => {
        try {
          if(data.state == "own_change") {
            toast.success('Update successfull!');
          } else {
            toast.success('Customer successfully updated!');
            this.props.history.goBack();
          }
        } catch(err) {
        }

      },
      (error) => {
        console.log('Failed to update profile ', error)
      });
  };

  onPermissionChanged(key, value) {
    const { inputs } = this.state;
    inputs.permissions[key] = value;
    this.setState({inputs});
  }


  render() {
    const {title, createNewAccount} = this.props;
    const { inputs: {company, forename, surname, vat, address, email, country} } = this.state;

    return  (
      <Card small className="mb-4">
        <CardHeader className="border-bottom">
          <h6 className="m-0">{title}</h6>
        </CardHeader>
        <ListGroup flush>
          <ListGroupItem className="p-3">
            <Row>
              <Col>
                <Form>
                  <Row form>
                    {/* First Name */}
                    <Col md="6" className="form-group">
                      <label htmlFor="feFirstName">Company Name</label>
                      <FormInput
                        id="feCompany"
                        placeholder="Company Name"
                        value={company}
                        onChange={(e) => {this.onTextFieldChanged("company", e.target.value)}}
                      />
                    </Col>
                    <Col md="6" className="form-group">
                      <label htmlFor="feFirstName">
                        Tax number (VAT)</label>
                      <FormInput
                        id="feVAT"
                        placeholder="Tax number"
                        value={vat}
                        onChange={(e) => {this.onTextFieldChanged("vat", e.target.value)}}
                      />
                    </Col>

                  </Row>
                  <Row form>
                    {/* Email */}
                    <Col md="12" className="form-group">
                      <label htmlFor="feEmail">Email</label>
                      <FormInput
                        type="email"
                        id="feEmail"
                        placeholder="Email Address"
                        value={email}
                        onChange={(e) => {this.onTextFieldChanged("email", e.target.value)}}
                        autoComplete="email"
                      />
                    </Col>
                    {/* Password */}

                  </Row>
                  <Row form>
                    {/* First Name */}
                    <Col md="6" className="form-group">
                      <label htmlFor="feFirstName">Firstname</label>
                      <FormInput
                        id="feFirstName"
                        placeholder="Firstname"
                        value={forename}
                        onChange={(e) => {this.onTextFieldChanged("forename", e.target.value)}}
                      />
                    </Col>
                    {/* Last Name */}
                    <Col md="6" className="form-group">
                      <label htmlFor="feLastName">Lastname</label>
                      <FormInput
                        id="feLastName"
                        placeholder="Lastname"
                        value={surname}
                        onChange={(e) => {this.onTextFieldChanged("surname", e.target.value)}}
                      />
                    </Col>
                  </Row>
                  <Row form>

                    <Col md="12" className="form-group">
                      <label htmlFor="feEmail">Address</label>
                      <FormInput
                        type="text"
                        id="feAddress"
                        placeholder="Samplestr. 18"
                        value={address.street}
                        onChange={(e) => {this.onAddressChanged("street", e.target.value)}}
                      />
                    </Col>
                  </Row>
                  <Row form>
                    {/* First Name */}
                    <Col md="6" className="form-group">
                      <label htmlFor="feFirstName">ZIP</label>
                      <FormInput
                        id="fePLZ"
                        placeholder="ZIP"
                        value={address.zip}
                        onChange={(e) => {this.onAddressChanged("zip", e.target.value)}}
                      />
                    </Col>
                    {/* Last Name */}
                    <Col md="6" className="form-group">
                      <label htmlFor="feLastName">City</label>
                      <FormInput
                        id="feCity"
                        placeholder="City"
                        value={address.city}
                        onChange={(e) => {this.onAddressChanged("city", e.target.value)}}
                      />
                    </Col>
                  </Row>
                  <Row form>
                    {/* Email */}
                    <Col md="12" className="form-group">
                      <label htmlFor="feEmail">Country</label>
                      <FormInput
                        type="country"
                        id="country"
                        placeholder="Country"
                        value={address.country}
                        onChange={(e) => {this.onAddressChanged("country", e.target.value)}}
                      />
                    </Col>
                    {/* Password */}

                  </Row>
                  {this.props.currentUser.roles.includes("admin")
                    ? <Row>
                      <Col md="12" className="form-group">
                        <label htmlFor="feEmail">Permission</label>
                        <label className="container">API Access
                          <input checked={this.state.inputs.permissions.api} type="checkbox" onChange={(e) => {this.onPermissionChanged("api", e.target.checked)} }  />
                          <span className="checkmark"></span>
                        </label>
                      </Col>
                    </Row>
                    : null
                  }

                  {createNewAccount
                    ? <Button onClick={this.onCreateAccount} theme="accent">{"Create Account"}</Button>
                    : <Button onClick={this.onUpdateAccount} theme="accent">{"Update Account"}</Button>
                  }
                </Form>
              </Col>
            </Row>
          </ListGroupItem>
        </ListGroup>
      </Card>
    )
  }
}



UserAccountDetails.propTypes = {
  /**
   * The component's title.
   */
  title: PropTypes.string
};

UserAccountDetails.defaultProps = {
  title: "Account Informationen"
};

const mapStateToProps = state => ({
  currentUser: state.authentication.user
});

const mapDispatchToProps = dispatch => ({
  updateAccount: (info, successCb, errorCb) => dispatch(updateAccount(info, successCb, errorCb)),
  createCustomer: (info, successCb, errorCb) => dispatch(createCustomer(info, successCb, errorCb)),
});
export default withRouter(connect(mapStateToProps, mapDispatchToProps)(UserAccountDetails));
