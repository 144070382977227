import React from "react";
import ReactTable from "react-table";
import FuzzySearch from "fuzzy-search";
import dateFormat from "dateformat";
import {
  Container,
  Row,
  Col,
  ButtonGroup,
  Button,
  Card,
  CardHeader,
  CardBody,
  FormSelect,
  InputGroup,
  InputGroupAddon,
  InputGroupText,
  FormInput
} from "shards-react";
import { withRouter } from 'react-router';
import { connect } from 'react-redux';
import {fetchAllUsers} from "../reducers/user/actions";
import PageTitle from "../components/common/PageTitle";
import getCustomerData from "../data/customer-sample-data";
import getUserDummyData from "../data/user-device-sample-data";


class UsersOverview extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      pageSizeOptions: [5, 10, 15, 20, 25, 30],
      pageSize: 10,
      allUsers: [],
      isAdmin: false,
    };

    this.searcher = null;

    this.getStatusClass = this.getStatusClass.bind(this);
    this.handlePageSizeChange = this.handlePageSizeChange.bind(this);
    this.handleFilterSearch = this.handleFilterSearch.bind(this);
    this.handleItemEdit = this.handleItemEdit.bind(this);
    this.handleItemDelete = this.handleItemDelete.bind(this);
    this.handleItemConfirm = this.handleItemConfirm.bind(this);
    this.handleItemViewDetails = this.handleItemViewDetails.bind(this);
    this.onUserLoaded = this.onUserLoaded.bind(this);
  }

  componentWillMount() {
    const { scope } = this.props.match.params;





    const allUsers = [];

    this.setState({
      ...this.state,
      allUsers
    });

    // Initialize the fuzzy searcher.
    this.searcher = new FuzzySearch(allUsers, ["customer", "status"], {
      caseSensitive: false
    });
  }

  componentDidMount() {
    this.props.fetchAllUsers((data) => {
      console.log("fetchAllUsers Success", data)
      //this.setState({allUsers: data})
      this.onUserLoaded(data);
    }, (err)=> {
      console.log("fetchAllUsers Errir ", err)
    })
  }

  onUserLoaded(user) {
    this.setState({allUsers: user})
  }

  /**
   * Returns the appropriate status class for the `Status` column.
   */
  getStatusClass(status) {
    const statusMap = {
      Cancelled: "danger",
      Complete: "success",
      Pending: "warning"
    };

    return `text-${statusMap[status]}`;
  }

  /**
   * Handles the page size change event.
   */
  handlePageSizeChange(e) {
    this.setState({
      ...this.state,
      pageSize: e.target.value
    });
  }

  /**
   * Handles the global search.
   */
  handleFilterSearch(e) {
    this.setState({
      ...this.state,
      allUsers: this.searcher.search(e.target.value)
    });
  }

  /**
   * Mock method for editing transactions.
   */
  handleItemEdit(row) {
    // alert(`Editing transaction "${row.original._id}"!`);
    var route = {
      pathname: '/create-update-user/'+row.original._id,
      search: '',
      state: { user: row.original }
    }
    this.props.history.push(route);
  }

  /**
   * Mock method for deleting transactions.
   */
  handleItemDelete(row) {
   // alert(`Deleting transaction "${row.original.id}"!`);
  }

  /**
   * Mock method for confirming transactions.
   */
  handleItemConfirm(row) {
    alert(`Confirming transaction "${row.original.id}"!`);
  }

  /**
   * Mock method for confirming transactions.
   */
  handleItemViewDetails(row) {
    alert(`Viewing details for "${row.original.id}"!`);

  }

  getAdminActions(row) {
    /*
      Admin
    */

    return (
      <ButtonGroup size="sm" className="d-table mx-auto">
        {/*<Button*/}
          {/*theme="white"*/}
          {/*onClick={() => this.handleItemViewDetails(row)}*/}
        {/*>*/}
          {/*<i className="material-icons">&#xE870;</i>*/}
        {/*</Button>*/}
        <Button theme="white" onClick={() => this.handleItemEdit(row)}>
          <i className="material-icons">&#xE254;</i>
        </Button>
        <Button theme="white" onClick={() => this.handleItemDelete(row)}>
          <i className="material-icons">&#xE872;</i>
        </Button>
      </ButtonGroup>
    )
  }

  onCreate() {
    //alert("on create cicke")
    this.props.history.push('/create-update-user/new');
  }

  render() {
    const {  pageSize, pageSizeOptions } = this.state;
    const { allUsers } = this.props;

    const tableColumn = [
      {
        Header: "Name",
        accessor: "name",
        maxWidth: 250,
        Cell: row =>{
          const {forename, surname} =  row.original;
          var nameString = "";
          if(forename) nameString += forename;
          if(surname) nameString = nameString + " " + surname;
          return nameString;
        }

      },
      {
        Header: "Company",
        accessor: "company",
        maxWidth: 250,
        className: "text-center"
      },
      {
        Header: "Email",
        accessor: "email",
        className: "text-center"
      },
      {
        Header: "Created Date",
        accessor: "createdAt",
        className: "text-center",
        maxWidth: 200,
        Cell: row =>
          dateFormat(new Date(row.original.createdAt), "dd.mm.yyyy")
      },
      {
        Header: "Actions",
        accessor: "actions",
        maxWidth: 150,
        sortable: false,
        Cell: row => this.getAdminActions(row)
      }
    ]

    const tableColumns = tableColumn;

    return (
      <Container fluid className="main-content-container px-4 pb-4">
        <Row noGutters className="page-header py-4">
          <PageTitle title={"Your Customer"} subtitle="Overview" className="text-sm-left mb-3" />
          {/*<Col sm="4" className="d-flex ml-auto my-auto">*/}
          {/*<RangeDatePicker className="justify-content-end" />*/}
          {/*</Col>*/}

        </Row>
        <div style={{paddingBottom: 10, display: "flex", justifyContent: "flex-end"}}>
          <Button onClick={this.onCreate.bind(this)}>New User</Button>
        </div>
        <Card className="p-0">
          <CardHeader className="p-0">
            <Container fluid className="file-manager__filters border-bottom">
              <Row>
                {/* Filters :: Page Size */}
                <Col className="file-manager__filters__rows d-flex" md="6">
                  <span>Show</span>
                  <FormSelect
                    size="sm"
                    value={this.state.pageSize}
                    onChange={this.handlePageSizeChange}
                  >
                    {pageSizeOptions.map((size, idx) => (
                      <option key={idx} value={size}>
                        {size} rows
                      </option>
                    ))}
                  </FormSelect>
                </Col>


                {/* Filters :: Search */}
                <Col className="file-manager__filters__search d-flex" md="6">
                  <InputGroup seamless size="sm" className="ml-auto">
                    <InputGroupAddon type="prepend">
                      <InputGroupText>
                        <i className="material-icons">search</i>
                      </InputGroupText>
                    </InputGroupAddon>
                    <FormInput onChange={this.handleFilterSearch} />
                  </InputGroup>
                </Col>
              </Row>
            </Container>
          </CardHeader>
          <CardBody className="p-0">
            <div className="">
              <ReactTable
                columns={tableColumns}
                data={allUsers}
                pageSize={pageSize}
                showPageSizeOptions={false}
                resizable={false}
              />
            </div>
          </CardBody>
        </Card>
      </Container>
    );
  }
}

const mapStateToProps = state => ({
    allUsers: state.user.allUsers
});

const mapDispatchToProps = dispatch => ({
  fetchAllUsers: (successCb, errorCb) => dispatch(fetchAllUsers(successCb, errorCb)),
});
export default withRouter(connect(mapStateToProps, mapDispatchToProps)(UsersOverview));
