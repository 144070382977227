

export default () => [{
  "id": 1,
  "date": "12/1/2017",
  "customer": "Berton Overthrow",
  "products": 104,
  "status": "Complete",
  "total": "$6541.92"
}, {
  "id": 2,
  "date": "7/2/2018",
  "customer": "Waylan Jochanany",
  "products": 434,
  "status": "Complete",
  "total": "$1477.65"
}, {
  "id": 3,
  "date": "8/12/2018",
  "customer": "Frasier Sail",
  "products": 227,
  "status": "Cancelled",
  "total": "$8628.05"
}, {
  "id": 4,
  "date": "5/14/2018",
  "customer": "Phineas MacArd",
  "products": 237,
  "status": "Cancelled",
  "total": "$6964.24"
}, {
  "id": 5,
  "date": "1/30/2018",
  "customer": "Davidde Fisbey",
  "products": 213,
  "status": "Complete",
  "total": "$7472.82"
}, {
  "id": 6,
  "date": "1/24/2018",
  "customer": "Damon Tarver",
  "products": 324,
  "status": "Complete",
  "total": "$2106.83"
}, {
  "id": 7,
  "date": "10/11/2017",
  "customer": "Rora Sommers",
  "products": 74,
  "status": "Cancelled",
  "total": "$3218.88"
}, {
  "id": 8,
  "date": "5/27/2018",
  "customer": "Timothee Satford",
  "products": 11,
  "status": "Pending",
  "total": "$5221.35"
}, {
  "id": 9,
  "date": "12/16/2017",
  "customer": "Leonard Jenik",
  "products": 79,
  "status": "Complete",
  "total": "$8104.03"
}, {
  "id": 10,
  "date": "6/6/2018",
  "customer": "Clemens Raspel",
  "products": 37,
  "status": "Pending",
  "total": "$5551.26"
}, {
  "id": 11,
  "date": "4/1/2018",
  "customer": "Noreen Pirri",
  "products": 291,
  "status": "Pending",
  "total": "$3677.70"
}, {
  "id": 12,
  "date": "10/13/2017",
  "customer": "Robina McNysche",
  "products": 115,
  "status": "Pending",
  "total": "$6807.83"
}, {
  "id": 13,
  "date": "4/11/2018",
  "customer": "Ancell Strelitz",
  "products": 278,
  "status": "Complete",
  "total": "$1374.93"
}, {
  "id": 14,
  "date": "10/24/2017",
  "customer": "Cully Brave",
  "products": 158,
  "status": "Pending",
  "total": "$7913.69"
}, {
  "id": 15,
  "date": "11/24/2017",
  "customer": "Lizette Ousbie",
  "products": 148,
  "status": "Cancelled",
  "total": "$5051.54"
}, {
  "id": 16,
  "date": "11/19/2017",
  "customer": "Jennette Targetter",
  "products": 422,
  "status": "Complete",
  "total": "$8708.35"
}, {
  "id": 17,
  "date": "8/12/2018",
  "customer": "Ike McFall",
  "products": 134,
  "status": "Complete",
  "total": "$1057.82"
}, {
  "id": 18,
  "date": "1/31/2018",
  "customer": "Myrtie Rao",
  "products": 53,
  "status": "Complete",
  "total": "$824.90"
}, {
  "id": 19,
  "date": "4/27/2018",
  "customer": "Burch Hanton",
  "products": 363,
  "status": "Complete",
  "total": "$3895.29"
}, {
  "id": 20,
  "date": "2/1/2018",
  "customer": "Ronny Bathurst",
  "products": 212,
  "status": "Complete",
  "total": "$3835.73"
}, {
  "id": 21,
  "date": "1/15/2018",
  "customer": "Gina Pullinger",
  "products": 462,
  "status": "Cancelled",
  "total": "$9175.35"
}, {
  "id": 22,
  "date": "4/24/2018",
  "customer": "Claudelle Dunbobbin",
  "products": 206,
  "status": "Complete",
  "total": "$2268.94"
}, {
  "id": 23,
  "date": "11/2/2017",
  "customer": "Sergei McTavish",
  "products": 328,
  "status": "Complete",
  "total": "$8232.85"
}, {
  "id": 24,
  "date": "5/17/2018",
  "customer": "Persis Anthill",
  "products": 380,
  "status": "Cancelled",
  "total": "$6705.88"
}, {
  "id": 25,
  "date": "7/11/2018",
  "customer": "Antons Imlach",
  "products": 101,
  "status": "Complete",
  "total": "$7185.83"
}, {
  "id": 26,
  "date": "6/16/2018",
  "customer": "Colan Dodds",
  "products": 229,
  "status": "Complete",
  "total": "$6867.34"
}, {
  "id": 27,
  "date": "8/31/2018",
  "customer": "Eryn Goadbie",
  "products": 10,
  "status": "Complete",
  "total": "$4564.19"
}, {
  "id": 28,
  "date": "5/7/2018",
  "customer": "Frazer Geerling",
  "products": 292,
  "status": "Pending",
  "total": "$4788.81"
}, {
  "id": 29,
  "date": "2/3/2018",
  "customer": "Rhetta Barnewille",
  "products": 123,
  "status": "Cancelled",
  "total": "$9239.38"
}, {
  "id": 30,
  "date": "6/19/2018",
  "customer": "Marcella Siggee",
  "products": 469,
  "status": "Pending",
  "total": "$2741.51"
}, {
  "id": 31,
  "date": "9/19/2018",
  "customer": "Arielle Hawler",
  "products": 46,
  "status": "Cancelled",
  "total": "$5209.47"
}, {
  "id": 32,
  "date": "9/5/2018",
  "customer": "Chaddie Dancy",
  "products": 258,
  "status": "Complete",
  "total": "$7918.66"
}, {
  "id": 33,
  "date": "1/3/2018",
  "customer": "Ailbert Cuthbert",
  "products": 205,
  "status": "Pending",
  "total": "$3694.19"
}, {
  "id": 34,
  "date": "6/5/2018",
  "customer": "Alleyn Titcom",
  "products": 194,
  "status": "Complete",
  "total": "$7299.82"
}, {
  "id": 35,
  "date": "3/8/2018",
  "customer": "Coleman Luthwood",
  "products": 341,
  "status": "Cancelled",
  "total": "$3914.58"
}, {
  "id": 36,
  "date": "7/17/2018",
  "customer": "Ferdie Kersey",
  "products": 131,
  "status": "Complete",
  "total": "$8126.78"
}, {
  "id": 37,
  "date": "10/23/2017",
  "customer": "Daniel Jacques",
  "products": 484,
  "status": "Complete",
  "total": "$888.47"
}, {
  "id": 38,
  "date": "4/14/2018",
  "customer": "Wallache Dowgill",
  "products": 190,
  "status": "Pending",
  "total": "$5329.60"
}, {
  "id": 39,
  "date": "10/31/2017",
  "customer": "Jorey Broadbridge",
  "products": 101,
  "status": "Complete",
  "total": "$7244.68"
}, {
  "id": 40,
  "date": "8/7/2018",
  "customer": "Flint Polle",
  "products": 93,
  "status": "Complete",
  "total": "$2229.05"
}, {
  "id": 41,
  "date": "12/16/2017",
  "customer": "Perri Lainton",
  "products": 401,
  "status": "Pending",
  "total": "$4697.39"
}, {
  "id": 42,
  "date": "1/19/2018",
  "customer": "Clerkclaude Golightly",
  "products": 381,
  "status": "Cancelled",
  "total": "$306.84"
}, {
  "id": 43,
  "date": "7/19/2018",
  "customer": "Anthe Archdeckne",
  "products": 185,
  "status": "Complete",
  "total": "$1131.60"
}, {
  "id": 44,
  "date": "9/25/2018",
  "customer": "Skipp Mehmet",
  "products": 14,
  "status": "Pending",
  "total": "$8211.85"
}, {
  "id": 45,
  "date": "1/25/2018",
  "customer": "Ivar Pering",
  "products": 31,
  "status": "Pending",
  "total": "$7035.55"
}, {
  "id": 46,
  "date": "4/14/2018",
  "customer": "Arvie Heintsch",
  "products": 324,
  "status": "Cancelled",
  "total": "$3249.78"
}, {
  "id": 47,
  "date": "7/23/2018",
  "customer": "Archibold Gordon",
  "products": 229,
  "status": "Cancelled",
  "total": "$3429.54"
}, {
  "id": 48,
  "date": "12/28/2017",
  "customer": "Derry Errigo",
  "products": 465,
  "status": "Complete",
  "total": "$746.53"
}, {
  "id": 49,
  "date": "8/17/2018",
  "customer": "Caterina Bonallack",
  "products": 18,
  "status": "Complete",
  "total": "$8165.86"
}, {
  "id": 50,
  "date": "6/5/2018",
  "customer": "Buckie Starsmeare",
  "products": 390,
  "status": "Complete",
  "total": "$4042.83"
}];
