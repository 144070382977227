import React from "react";
import {
  Card,
  Container,
  CardHeader,
  ListGroup,
  ListGroupItem,
  Row,
  Col,
  Form,
  FormGroup,
  FormInput,
  FormSelect,
  FormTextarea,
  Button
} from "shards-react";
import PageTitle from "../components/common/PageTitle";
import Select from 'react-select';
import getCustomerData from "../data/customer-sample-data";
import {createCustomer, updateAccount} from "../reducers/user/actions";
import {createDevice, updateDevice} from "../reducers/devices/actions";
import {connect} from "react-redux";
import {withRouter} from "react-router-dom";
import {toast} from "react-toastify/index";
import 'react-toastify/dist/ReactToastify.css';


class CreateUpdateDevice extends React.Component {

  constructor(props) {
    super(props)

    this.state = {
      device: {
        qrcode: props.device && props.device.qrcode  ? props.device.qrcode : '',
        thingsToken: props.device && props.device.thingsToken ? props.device.thingsToken : '',
        deviceType: props.device && props.device.deviceType ? props.device.deviceType : '1M2M',
        user: props.device && props.device.user  ? props.device.user : '',
      }
    }

    const userData = this.props.allUsers;
    this.userSelectOptions =  [{label: "No User", value: {_id: -1}}];
    for(var i = 0; i < userData.length; i++) {
      this.userSelectOptions.push({value: userData[i], label: userData[i].forename + " "+ userData[i].surname})
    }

  }

  componentWillReceiveProps(props) {
    this.state = {
      device: {
        qrcode: props.device && props.device.qrcode  ? props.device.qrcode : '',
        thingsToken: props.device && props.device.thingsToken ? props.device.thingsToken : '',
        label: props.device && props.device.label ? props.device.label : '',
        deviceType: props.device && props.device.deviceType ? props.device.deviceType : '1M2M',
        user: props.device && props.device.user  ? props.device.user : '',
      }
    };
    this.setState(props)
  }

  componentWillMount() {
    const {deviceid} = this.props.match.params;
    if (deviceid == "new") {
      this.setState({user: null})
    } else {
      // TODO Fetch User
      if (this.props.location.state) {
        var device = this.props.location.state.device
        console.log("device", device);
        this.setState({device: device})
      } else {
        // TODO Fetch User
      }

   }

  }

  onUpdateClicked() {
    var profile = "";
    for (var property in this.state) {
      if (this.state.hasOwnProperty(property)) {
        profile += property + " =  " + this.state[property]
      }
    }

    const {device} = this.state;
    console.log("Device to create", device)
    var apiCall = this.props.createDevice;
    if (device._id) {
      apiCall = this.props.updateDevice;
    }
    apiCall(device, (data) => {
      console.log("device created success", data);
      toast.success("Device successfully created");
      this.props.history.goBack();
    }, (err) => {
      toast.error(err.message);
      console.log("device created failed", err);
    })
  }

  onTextFieldChanged(stateName, value) {
    this.state.device[stateName] = value;
    this.setState({})
  }

  handleChange = (selectedOption) => {
    this.setState({ selectedOption });
    this.state.device.deviceType = selectedOption.currentTarget.value;

    console.log(`Option selected:`, selectedOption.currentTarget.value);
  }



  render() {

    const isUpdate = this.state.device._id ? true : false;

    return (
      <Container fluid className="main-content-container px-4">
        <Row noGutters className="page-header py-4">
          <PageTitle title={this.state.device._id ? "Update Device ": "Create Device"} subtitle="Overview" md="12" className="ml-sm-auto mr-sm-auto"/>
        </Row>
        <Row>
          <Col lg="6">
            <Card small className="mb-4">
              <CardHeader className="border-bottom">
                <h6 className="m-0">{"Device Information"}</h6>
              </CardHeader>
              <ListGroup flush>
                <ListGroupItem className="p-3">
                  <Row>
                    <Col>
                      <Form>
                        <Row form>
                          {/* First Name */}
                          <Col md="6" className="form-group">
                            <label htmlFor="feFirstName">QR-Code</label>
                            <FormInput
                              id="feCompany"
                              placeholder="QR-CODE"
                              disabled={{isUpdate}}
                              value={this.state.device.qrcode}
                              onChange={(e) => {this.onTextFieldChanged("qrcode", e.target.value)}}
                            />
                          </Col>
                          {/*<Col md="6" className="form-group">*/}
                            {/*<label htmlFor="feFirstName">Steuernummer (VAT)</label>*/}
                            {/*<FormInput*/}
                              {/*id="feVAT"*/}
                              {/*placeholder="Steuernummer"*/}
                              {/*value={this.state.vat}*/}
                              {/*onChange={(e) => {this.onTextFieldChanged("vat", e.target.value)}}*/}
                            {/*/>*/}
                          {/*</Col>*/}

                        </Row>
                        <Row form>
                          {/* First Name */}
                          <Col md="10" className="form-group">
                            <label htmlFor="feFirstName">ThingsIO Token</label>
                            <FormInput
                              id="fe'FirstName"
                              placeholder="ThingsIO-Token"
                              value={this.state.device.thingsToken}
                              onChange={(e) => {this.onTextFieldChanged("thingsToken", e.target.value)}}
                            />
                          </Col>
                          {/* Last Name */}
                          {/*<Col md="6" className="form-group">*/}
                            {/*<label htmlFor="feLastName">Nachname</label>*/}
                            {/*<FormInput*/}
                              {/*id="feLastName"*/}
                              {/*placeholder="Name"*/}
                              {/*value={this.state.lastname}*/}
                              {/*onChange={(e) => {this.onTextFieldChanged("lastname", e.target.value)}}*/}
                            {/*/>*/}
                          {/*</Col>*/}
                        </Row>
                        <Row form>
                          {/* First Name */}
                          <Col md="10" className="form-group">
                            <label htmlFor="feLabel">Label</label>
                            <FormInput
                              id="feLabel"
                              placeholder="Label"
                              value={this.state.device.label}
                              onChange={(e) => {this.onTextFieldChanged("label", e.target.value)}}
                            />
                          </Col>
                          {/* Last Name */}
                          {/*<Col md="6" className="form-group">*/}
                          {/*<label htmlFor="feLastName">Nachname</label>*/}
                          {/*<FormInput*/}
                          {/*id="feLastName"*/}
                          {/*placeholder="Name"*/}
                          {/*value={this.state.lastname}*/}
                          {/*onChange={(e) => {this.onTextFieldChanged("lastname", e.target.value)}}*/}
                          {/*/>*/}
                          {/*</Col>*/}
                        </Row>
                        {/*<Row form>*/}

                          {/*<Col md="4" className="form-group">*/}
                            {/*<label htmlFor="feEmail">Type</label>*/}
                            {/*<FormSelect*/}
                              {/*value={this.state.selectedOption}*/}
                              {/*onChange={this.handleChange}*/}
                              {/*id="feInputState">*/}
                              {/*<option>1M2M</option>*/}
                              {/*<option>Greatech</option>*/}
                            {/*</FormSelect>*/}
                          {/*</Col>*/}
                        {/*</Row>*/}

                        <Row>
                          <Col md={"12"} className={"form-group"}>
                            <label htmlFor="feEmail">Kunde</label>
                            <Select
                              components={{ ClearIndicator }}
                              styles={{ clearIndicator: ClearIndicatorStyles }}
                              onChange={(e) => {this.onTextFieldChanged("user", e.value)}}
                              closeMenuOnSelect={true}
                              options={this.userSelectOptions}
                            />
                          </Col>
                        </Row>

                        <Button onClick={this.onUpdateClicked.bind(this)} theme="accent">{this.state.device._id ? "Update Device ": "Create Device"}</Button>
                      </Form>
                    </Col>
                  </Row>
                </ListGroupItem>
              </ListGroup>
            </Card>
          </Col>

        </Row>
      </Container>
    )
  }

}

const options = [
  { value: 'chocolate', label: 'Chocolate' },
  { value: 'strawberry', label: 'Strawberry' },
  { value: 'vanilla', label: 'Vanilla' }
];

const CustomClearText = () => 'clear all';

const ClearIndicator = (props) => {
  const { children = <CustomClearText/>, getStyles, innerProps: { ref, ...restInnerProps } } = props;
  return (
    <div {...restInnerProps} ref={ref} style={getStyles('clearIndicator', props)}>
      <div style={{ padding: '0px 5px' }}>
        {children}
      </div>
    </div>
  );
};

const ClearIndicatorStyles = (base, state) => ({
  ...base,
  cursor: 'pointer',
  color: state.isFocused ? 'blue' : 'black',
});

const mapStateToProps = state => ({
  allUsers: state.user.allUsers
});

const mapDispatchToProps = dispatch => ({
  updateAccount: (info, successCb, errorCb) => dispatch(updateAccount(info, successCb, errorCb)),
  createDevice: (device, successCb, errorCb) => dispatch(createDevice(device, successCb, errorCb)),
  updateDevice: (device, successCb, errorCb) => dispatch(updateDevice(device, successCb, errorCb)),
});
export default withRouter(connect(mapStateToProps, mapDispatchToProps)(CreateUpdateDevice));



