import {
  FETCH_DEVICES,
} from "./actions";

const initialState = {
  allDevices: [],
};

export default function device(state = initialState, action={}) {
  switch(action.type) {
    case FETCH_DEVICES: {
      const { devices } = action;
      // var data = devices.filter(function (el) {
      //   return el.deviceType == "1M2M"
      // });
      return {
        ...state,
        allDevices: devices
      };
    }
    default: {
      return state;
    }
  }
}
