import React from "react";
import { Redirect } from "react-router-dom";

// Layout Types
import { DefaultLayout, HeaderNavigation, IconSidebar } from "./layouts";
import Analytics from "./views/Analytics";
import BlogOverview from "./views/BlogOverview";
import UserProfileLite from "./views/UserProfileLite";
import AddNewPost from "./views/AddNewPost";
import IconSidebarView from "./views/IconSidebar";
import EditUserProfile from "./views/EditUserProfile";
import Calendar from "./views/Calendar";
import Tables from "./views/Tables";
import OnlineStore from "./views/OnlineStore";
import UserProfile from "./views/UserProfile";
import UserProfileSoFlow from "./views/UserProfileSoFlow";
import Errors from "./views/Errors";
import FileManagerCards from "./views/FileManagerCards";
import ComponentsOverview from "./views/ComponentsOverview";
import TransactionHistory from "./views/TransactionHistory";
import DeviceOverview from "./views/DeviceOverview";
import BlogPosts from "./views/BlogPosts";
import FileManagerList from "./views/FileManagerList";
import HeaderNav from "./views/HeaderNavigation";
import LocationMap from "./views/LocationMap";
import UserOverview from "./views/UserOverview";
import CreateUpdateUser from "./views/CreateUpdateUser";
import CreateUpdateDevice from "./views/CreateUpdateDevice";

const BlankIconSidebarLayout = ({ children }) => (
  <IconSidebar noNavbar noFooter>
    {children}
  </IconSidebar>
);

export default [
  {
    path: "/ecommerce",
    layout: DefaultLayout,
    component: OnlineStore
  },
  {
    path: "/blog-overview",
    layout: DefaultLayout,
    component: BlogOverview
  },
  {
    path: "/locations",
    layout: DefaultLayout,
    component: LocationMap
  },
  {
    path: "/user-profile",
    layout: DefaultLayout,
    component: UserProfile
  },
  {
    path: "/user-profile-lite",
    layout: DefaultLayout,
    component: UserProfileLite
  },
  {
    path: "/user",
    layout: DefaultLayout,
    component: UserProfileSoFlow
  },
  {
    path: "/user-overview",
    layout: DefaultLayout,
    component: UserOverview
  },
  {
    path: "/create-update-user/:userid",
    layout: DefaultLayout,
    component: CreateUpdateUser
  },
  {
    path: "/edit-user-profile",
    layout: DefaultLayout,
    component: EditUserProfile
  },
  {
    path: "/file-manager-list",
    layout: DefaultLayout,
    component: FileManagerList
  },
  {
    path: "/file-manager-cards",
    layout: DefaultLayout,
    component: FileManagerCards
  },
  {
    path: "/transaction-history",
    layout: DefaultLayout,
    component: TransactionHistory
  },
  {
    path: "/device-overview/:scope",
    layout: DefaultLayout,
    component: DeviceOverview
  },
  {
    path: "/create-update-device/:deviceid",
    layout: DefaultLayout,
    component: CreateUpdateDevice
  },
  {
    path: "/calendar",
    layout: DefaultLayout,
    component: Calendar
  },
  {
    path: "/add-new-post",
    layout: DefaultLayout,
    component: AddNewPost
  },
  {
    path: "/errors",
    layout: BlankIconSidebarLayout,
    component: Errors
  },
  {
    path: "/components-overview",
    layout: DefaultLayout,
    component: ComponentsOverview
  },
  {
    path: "/tables",
    layout: DefaultLayout,
    component: Tables
  },
  {
    path: "/blog-posts",
    layout: DefaultLayout,
    component: BlogPosts
  },
  {
    path: "/header-navigation",
    layout: HeaderNavigation,
    component: HeaderNav
  },
  {
    path: "/icon-sidebar-nav",
    layout: IconSidebar,
    component: IconSidebarView
  }
];
