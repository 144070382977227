import React from "react";
import {
  Card,
  Container,
  CardHeader,
  ListGroup,
  ListGroupItem,
  Row,
  Col,
  Form,
  FormGroup,
  FormInput,
  FormSelect,
  FormTextarea,
  Button
} from "shards-react";

import PageTitle from "../components/common/PageTitle";
import UserAccountDetails from "../components/user-profile-soflow/UserAccountDetails";
import ResetPassword from "../components/user-profile-soflow/ResetPassword";
import ApiKey from "../components/user-profile-soflow/ApiKey";

class CreateUpdateUser extends React.Component {

  constructor(props) {
    super(props)

    this.state = {
      user: null
    }


  }

  componentWillMount() {
    const {userid} = this.props.match.params;

    if(userid == "new") {
      this.setState({user: null})
    } else {
      // TODO Fetch User
      if(this.props.location.state) {
        var user = this.props.location.state.user
        console.log("user", user);
        this.setState({user: user})
      } else {
        // TODO Fetch User
      }

    }
  }




  render() {
    return (
      <Container fluid className="main-content-container px-4">
        <Row noGutters className="page-header py-4">
          <PageTitle title={this.state.user ? "Update Customer" : "Create Customer"} subtitle="Overview" md="12" className="ml-sm-auto mr-sm-auto"/>
        </Row>
        <Row>
          <Col lg="6">
            <UserAccountDetails createNewAccount={this.state.user === null} user={this.state.user}/>
          </Col>

        </Row>
      </Container>
    )
  }

}


export default CreateUpdateUser;
