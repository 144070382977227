/*global google*/
/*global MarkerClusterer*/

import React from "react"
import { Container, Row, Col,Card, CardHeader,Form,
  InputGroup,
  InputGroupAddon,
  InputGroupText,
  FormInput,
  ButtonGroup,
  FormCheckbox,
  Button } from "shards-react";
import PageTitle from "./../components/common/PageTitle";
import DatePicker from "react-datepicker";
import {deleteDevice, fetchAllDevices, updateLabel, getDeviceSharing, getDeviceDataByDate,getDeviceDataBySharingToken} from "../reducers/devices/actions";
import {connect} from "react-redux";
import { withRouter } from 'react-router';
import moment from "moment";

var geocoder;
var map;
var markers = [];
var list;


class Shared extends React.Component {

  constructor(props) {
    super(props)
    const {token} = this.props.match.params;

    this.state = {
      containerHeight: 500,
      clientHeight:500,
      currentDeviceDetails: null,
      currentShareLink: "https://theftex.com/location/CzvKVsx0j8kwyC1",
      currentSharingPassword: "",
      currentSharingDate: "",
      startDate: new Date(),
      shareLink: false,
      passwordSharing: false,
      sharingDueDate: false,
      dataDate: new Date(),
      filter: "",
      token: token,
      deviceData: []
    }



    // TODO Fetch real devices


    this.initMap = this.initMap.bind(this);
    this.renderNewMarkersByDeviceData = this.renderNewMarkersByDeviceData.bind(this);
    this.mapInitialzied= false;
    this.flightPath = null;
  }

  componentDidMount() {
    const {token} = this.props.match.params;


    this.props.getDeviceDataBySharingToken(token, new Date(), "", this.onDeviceResponse.bind(this) , (error) => {
      console.log("error", error)
    })
    window.initMap = this.initMap;
    this.loadJS('https://maps.googleapis.com/maps/api/js?key=AIzaSyBM2m31gEoHHxq41ITLPOF_ouv_bVshui0&callback=initMap')
    if(this.container) {
      this.setState({containerHeight: this.container.clientHeight})
    }

  }

  onDeviceResponse(result) {
    //console.log("result", result)
    this.state.locations = result.locations;
    this.state.currentDeviceDetails = result.device;
    this.setState({locations: result.locations, currentDeviceDetails: result.device})
    if( this.mapInitialzied) {

      this.renderNewMarkersByDeviceData(this.state.locations);
    }
  }

  addDefaultMarkers(allDevices) {
    alert("test")
    if( this.mapInitialzied) {
      //
      this.renderNewMarkersByDeviceData(this.state.locations);
      var bounds = new google.maps.LatLngBounds();
      list.forEach(function (data, index, array) {

        var marker = new google.maps.Marker({
          position: new google.maps.LatLng(list[index][0], list[index][1]),
          map: map
        });
        marker.addListener('mouseover', function() {

        });

        marker.addListener('mouseout', function() {

        });
        markers.push(marker);

        //bounds.extend(marker.position);
      });
      //map.fitBounds(bounds);
      var markerCluster = new MarkerClusterer(map, markers,
        {imagePath: 'https://developers.google.com/maps/documentation/javascript/examples/markerclusterer/m'});

      if(markers.length > 0) {
        var latLng = markers[0].getPosition(); // returns LatLng object
        //map.setCenter(latLng);
        //map.setZoom(20);
      }
    }
  }


  loadJS(src) {
    var ref = window.document.getElementsByTagName("script")[0];
    var script = window.document.createElement("script");
    script.type = "text/javascript";
    script.src = src;
    script.async = true;
    ref.parentNode.insertBefore(script, ref);
  }

  initMap() {
    map = new google.maps.Map(document.getElementById('map-canvas-2'), {
      mapTypeId: google.maps.MapTypeId.ROADMAP,
      disableDefaultUI: true,
      center: new google.maps.LatLng(52.520008, 13.404954), //**Change the co-ordinates as you want**
      zoom: 3,
    });

    this.mapInitialzied = true;
    list = [

    ];

  }

  removeCurrentMarkers() {
    for(var i = 0; i < markers.length; i++) {
      markers[i].setMap(null);
    }

    if(this.flightPath) {
      this.flightPath.setMap(null);
    }
  }

  renderNewMarkersByDeviceData(data) {
   // console.log("getTodaysDeviceData", data)
    this.setState({deviceData: data})
    this.infoWindows  = [];
    this.removeCurrentMarkers();
    markers = [];
    for(var x = 0; x < data.length; x++) {
      console.log("markers", map)
      var marker = new google.maps.Marker({
        title:  (x+1).toString(),
        position: new google.maps.LatLng(parseFloat(data[x].data["lat"]), parseFloat(data[x].data["long"])),
        label: (x+1).toString(),
        map: map
      });
      marker.index = x;
      marker.addListener('mouseover', function() {

      });

      marker.addListener('mouseout', function() {

      });
      var infowindow = new google.maps.InfoWindow({
        content: "<div style='font-weight: bold'>Nr: "+(x+1).toString()+"</div><div>"+ moment(data[x].timestamp).format("HH:mm") +" Uhr</div><div>Lat: "+data[x]["lat"]+"</div><div>Lon: "+data[x]["long"]+"</div>"
      });

      this.infoWindows.push(infowindow);
      marker.addListener('click', this.onMarkerClicked.bind(this, marker));
      markers.push(marker);

      //bounds.extend(marker.position);
    }
    //console.log("markers", markers)
    if(markers[0]) {
      var latLng = markers[0].getPosition(); // returns LatLng object
      map.setCenter(latLng);
      map.setZoom(10);
    }
    var coordinates = []
    for(var i =0; i < markers.length; i++) {
      coordinates.push(markers[i].getPosition());
    }

    this.flightPath = new google.maps.Polyline({
      path: coordinates,
      strokeColor: "#FF0000",
      strokeOpacity: 1.0,
      strokeWeight: 2
    });

    this.flightPath.setMap(map);

  }

  onDeviceListElementClicked(index, item) {
    this.infoWindows = [];
    console.log("onDeviceListElementClicked", index, markers);
    this.onDetailClicked(item, index)
    if(markers[index+1]) {
      var latLng = markers[index+1].getPosition(); // returns LatLng object
      map.setCenter(latLng);
      map.setZoom(10);
    } else {
      console.log("marker not found")
    }

    this.loadDeviceData(item.qrcode)

  }

  loadDeviceData(qrCode) {
    this.props.getDeviceDataBySharingToken(this.state.token, this.state.dataDate,this.state.password, (data) => {
      this.state.locations = data.locations;
      this.state.device = data.device;
      this.renderNewMarkersByDeviceData(data.locations);
      // var markerCluster = new MarkerClusterer(map, markers,
      //   {imagePath: 'https://developers.google.com/maps/documentation/javascript/examples/markerclusterer/m'});
    }, (err) => {
      console.log("err", err)
    })
  }


  onMarkerClicked(marker, test) {
    // console.log("onmarkerclicked", marker, marker.index, test)
    for(var i = 0; i< this.infoWindows.length; i++) {
      this.infoWindows[i].close();
    }
    this.infoWindows[marker.index].open(map, marker);

  }

  onFilter(ev) {
    console.log("onFilter", ev.target.value);

    const {allDevices} = this.props;
    var filteredDevices = allDevices;
    if(ev.target.value.length > 0) {
      filteredDevices = allDevices.filter((item) => {
        var labelEqual = false;
        if(item.label) {
          labelEqual = item.label.toLowerCase().includes(ev.target.value.toLowerCase());
        }
        return item.qrcode.toLowerCase().includes(ev.target.value.toLowerCase()) || labelEqual
      })

      console.log("filtered Devices", filteredDevices);


    }

    this.setState({filteredDevices: filteredDevices, filter: ev.target.value})

  }


  onContainerMount(ref){
    if(ref && ref.clientHeight) {
      this.container = ref;
    }
  }

  onDetailClicked(item, index) {

    console.log("onDeviceDetailClicked", index, item)

    this.setState({currentDeviceDetails: item});
  }

  renderDeviceDetails() {
    const {currentDeviceDetails} = this.state;
    var label = this.state.currentDeviceDetails.label ? this.state.currentDeviceDetails.label : this.state.currentDeviceDetails.qrcode
    var lastUpdated = "-";
    var lastLat = "-";
    var lastLong = "-";
    if(currentDeviceDetails.location) {
      lastUpdated = currentDeviceDetails.location.updatedAt;
      lastLat = currentDeviceDetails.location.lat.toString();
      lastLong = currentDeviceDetails.location.long.toString();
    }
    return (
      <div className={"device-detail-sidebar"}>
        {this.renderDetailItem("Name", label)}
        <div style={{borderBottom: "1px solid #e1e5eb"}}></div>
        {this.renderDetailItem("Last Updated", lastUpdated)}
        <div style={{borderBottom: "1px solid #e1e5eb"}}></div>
        {this.renderDetailItem("Latitude", lastLat)}
        <div style={{borderBottom: "1px solid #e1e5eb"}}></div>
        {this.renderDetailItem("Longitude", lastLong)}
        <div style={{borderBottom: "1px solid #e1e5eb"}}></div>

        {/*<div style={{margin: 10}}>
          <label className="container">Link teilen

            <span className="checkmark"></span>
          </label>
          {this.state.shareLink &&
          <FormInput
            id="feVAT"
            placeholder="Steuernummer"
            value={window.location.origin + "/#/locations/" +this.state.sharingKey}
          />


          }
          {this.state.shareLink &&
          <label className="container">Passwortschutz
            <input type="checkbox" onChange={(e) => {this.setState({passwordSharing: e.target.checked})}}  />
            <span className="checkmark"></span>
          </label>
          }
          {this.state.shareLink && this.state.passwordSharing &&
          <input placeholder={"Wählen Sie ein Passwort für den öffentlichen Link"} type={"text"} className={"input-field"} />
          }
          {this.state.shareLink &&
          <label className="container">Ein Ablaufdatum setzen
            <input onChange={(e) => {this.setState({sharingDueDate: e.target.checked})}} type="checkbox"  />
            <span className="checkmark"></span>
          </label>
          }
          {this.state.shareLink && this.state.sharingDueDate &&
          <DatePicker
            className={"input-field"}
            selected={this.state.startDate}
            onChange={(e) => {this.setState({startDate: e})}}
          />
          }




        </div>*/}
      </div>

    )
  }

  renderDetailItem(title, value) {
    return (
      <div style={{margin: 15,display: "flex"}}>
        <div style={{color: "gray", flex: 1}}>
          {title}
        </div>
        <div style={{flex: 1}}>
          {value ? value : "-"}
        </div>

      </div>
    )
  }



  lastUpdatedLabel(item) {
    var lastUpdated = "-";
    var lastLat = "-";
    var lastLong = "-";
    if(item.location) {
      lastUpdated = item.updatedAt;
    }

    return lastUpdated;
  }

  renderDefaultDeviceSidebar() {

    if(this.state.currentDeviceDetails != null) {
      return this.renderDeviceDetails();
    }

    return (
      <div style={{height: "100%"}}>
        <Row style={{marginLeft: 0, marginRight: 0}} className="border-bottom py-2 bg-light">
          <Col lg="12" className="mb-2 mb-lg-0">
            <Form action="POST">
              <InputGroup value={this.state.filter} onChange={this.onFilter.bind(this)} seamless size="sm">
                <InputGroupAddon  type="prepend">
                  <InputGroupText>
                    <i className="material-icons">search</i>
                  </InputGroupText>
                </InputGroupAddon>
                <FormInput  value={this.state.filter} />
              </InputGroup>
            </Form>
          </Col>
        </Row>
        <div  style={{overflowY: "scroll", overflowX: "hidden", height: "100%"}}>

        </div>
      </div>
    )
  }

  onBack() {
    this.setState({currentDeviceDetails : null})
    this.removeCurrentMarkers();
  }

  renderSidebarHeader() {
    if(this.state.currentDeviceDetails != null) {
      return (
        <div style={{display: "flex", alignItems: "center"}}>
          <h6 className="m-0">{this.state.currentDeviceDetails != null ? "Device Details" : "Your Devices"}</h6>
        </div>
      )
    } else {
      return <h6 className="m-0">{this.state.currentDeviceDetails != null ? "Device Details" : "Your Devices"}</h6>;
    }
  }

  onDateChange(e) {
    this.state.dataDate = e;
    this.setState({dataDate: e});
    this.loadDeviceData(this.state.currentDeviceDetails.qrcode);
  }


  renderHeaderSelection() {

    if(this.state.currentDeviceDetails) {
      var label = this.state.currentDeviceDetails.label ? this.state.currentDeviceDetails.label : this.state.currentDeviceDetails.qrcode;

      return (
        <div >
          <span style={{paddingRight: 5}}>Selected Date:</span>
          <DatePicker
            className={"input-field"}
            selected={this.state.dataDate}
            onChange={this.onDateChange.bind(this)}
          />
        </div>

      )
    } else {
      return null;
    }

  }


  render() {
    return (
      <div className={"root-location-map-container"} style={{display: "flex"}}>
        <Container style={{flex: 8, height: "99%"}} fluid className="main-content-container px-4">
          {/* Page Header */}
          {/*<Row noGutters className="page-header py-4">*/}
          {/*<PageTitle title="Device Locations" subtitle="Dashboard" className="text-sm-left mb-3" />*/}
          {/*</Row>*/}
          <div style={{paddingTop: "1.5rem"}}  className={"row"} ref={this.onContainerMount.bind(this)}>

            <Col style={{height: "100%"}} md="9">
              <Card style={{height: "100%"}} className="mb-4">
                <CardHeader className="border-bottom">
                  <div style={{display: "flex", flexDirection: "row", alignItems: "center"}}>
                    <h6 style={{flex: 1}} className="m-0">Location Map</h6>
                    {this.renderHeaderSelection()}
                  </div>
                </CardHeader>
                <div style={{minHeight: this.state.containerHeight}} id="map-canvas-2">test</div>
              </Card>
            </Col>
          </div>

        </Container>

        <div className="device-side-bar"  style={{minWidth: 280, width: "20%",position: "fixed", right:0 ,height: "100%"}}>
          <div className={"location-device-nav"} style={{height: '100%'}}>
            <CardHeader className="border-bottom">
              {this.renderSidebarHeader()}
            </CardHeader>
            {this.renderDefaultDeviceSidebar()}
          </div>
        </div>

      </div>
    )
  }
}





const mapStateToProps = state => ({
  allDevices: state.device.allDevices

});

const mapDispatchToProps = dispatch => ({
  fetchAllDevices: (successCb, errorCb) => dispatch(fetchAllDevices(successCb, errorCb)),
  getDeviceSharing: (qrcode, successCb, errorCb) => dispatch(getDeviceSharing(qrcode, successCb, errorCb)),
  getDeviceDataByDate: (qrcode, date, successCb, errorCb) => dispatch(getDeviceDataByDate(qrcode, date, successCb, errorCb)),
  getDeviceDataBySharingToken: (token,date,password, successCb, errorCb) => dispatch(getDeviceDataBySharingToken(token,date,password, successCb, errorCb)),
});
export default withRouter(connect(mapStateToProps, mapDispatchToProps)(Shared));
