import React from "react";
import { Nav } from "shards-react";

import SidebarNavItem from "./SidebarNavItem";
import { Store } from "../../../flux";
import { NavLink as RouteNavLink } from "react-router-dom";
import { withRouter } from 'react-router';

import {
  NavItem,
  NavLink,
  DropdownMenu,
  DropdownItem,
  Collapse
} from "shards-react";
import {connect} from "react-redux";

class SidebarNavItems extends React.Component {
  constructor(props) {
    super(props)

    this.state = {
      navItems: props.user && props.user.roles ? Store.getSidebarItems(props.user.roles.includes("admin")) : []
    };

    this.onChange = this.onChange.bind(this);
  }

  componentWillMount() {
    Store.addChangeListener(this.onChange);
  }

  componentWillUnmount() {
    Store.removeChangeListener(this.onChange);
  }

  onChange() {
    this.setState({
      ...this.state,
      navItems: this.props.user && this.props.user.roles ? Store.getSidebarItems(this.props.user.roles.includes("admin")) : []
    });
  }

  render() {
    const logoutItem = {
      title: 'Logout',
      to: '/logout',
      htmlBefore: '<i class="material-icons">logout</i>',
      htmlAfter: '',
    }


    const { navItems: items } = this.state;
    return (
      <div className="nav-wrapper" style={{display: "flex", flexDirection: "column"}}>
        {items.map((nav, idx) => (
          <div key={idx}>
            <h6 className="main-sidebar__nav-title">{nav.title}</h6>
            {typeof nav.items !== "undefined" && nav.items.length && (
              <Nav className="nav--no-borders flex-column">
                {nav.items.map((item, idx) => (
                  <SidebarNavItem key={idx} item={item} />
                ))}

              </Nav>
            )}
          </div>
        ))}
        <div style={{flex: 1, alignContent: "flex-end", display: "flex", flexDirection: "column", justifyContent: "flex-end"}}>
          <div className={"logout-menu-item"} style={{display: "flex", justifyContent: "center", width: "100%"}}>
        <NavLink
          className={"logout-button"}
          tag={false ? "a" : RouteNavLink}
          to={false ? "#" : logoutItem.to}
        >
          {logoutItem.htmlBefore && (
            <div
              className="d-inline-block item-icon-wrapper"
              dangerouslySetInnerHTML={{ __html: logoutItem.htmlBefore }}
            />
          )}
          {logoutItem.title && <span>{logoutItem.title}</span>}
          {logoutItem.htmlAfter && (
            <div
              className="d-inline-block item-icon-wrapper"
              dangerouslySetInnerHTML={{ __html: logoutItem.htmlAfter }}
            />
          )}
        </NavLink>
          </div>
        </div>
      </div>
    )
  }
}


const mapStateToProps = state => ({
  user: state.authentication.user
});

const mapDispatchToProps = dispatch => ({

});
export default withRouter(connect(mapStateToProps, mapDispatchToProps)(SidebarNavItems));
