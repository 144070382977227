/* eslint-disable no-unused-vars */
/* eslint-disable no-console */

import React, { PureComponent, Fragment } from 'react';
import PropTypes from 'prop-types';

import { connect } from 'react-redux';
import { withRouter } from 'react-router';

import {logout} from "../reducers/authentication/actions";

const mapStateToProps = state => ({
  authenticated: state.authentication.authenticated,
});

const mapDispatchToProps = dispatch => ({
  logoutUser: () => dispatch(logout()),
});

/**
 * Logout
 */
class Logout extends PureComponent {
  static propTypes = {
    authenticated: PropTypes.bool.isRequired,
    history: PropTypes.shape({
      push: PropTypes.func.isRequired,
    }).isRequired,
    logoutUser: PropTypes.func.isRequired,
    redirectTo: PropTypes.string,
  }

  static defaultProps = {
    authenticated: false,
    redirectTo: '/login',
  }

  componentDidMount() {
    this.props.logoutUser();
    this.props.history.push(this.props.redirectTo);
  }

  render() {
    return (
      <Fragment />
    );
  }
}

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(Logout));
