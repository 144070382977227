import React from "react";
import PropTypes from "prop-types";
import {
  Card,
  CardHeader,
  ListGroup,
  ListGroupItem,
  Row,
  Col,
  Form,
  FormGroup,
  FormInput,
  FormSelect,
  FormTextarea,
  Button
} from "shards-react";

const ResetPassword = ({ title }) => (
  <Card small className="mb-4">
    <CardHeader className="border-bottom">
      <h6 className="m-0">{title}</h6>
    </CardHeader>
    <ListGroup flush>
      <ListGroupItem className="p-3">
        <Row>
          <Col>
            <Form>
              <Row form>
                {/* First Name */}
                <Col md="12" className="form-group">
                  <label htmlFor="fePassword">New Password</label>
                  <FormInput
                    type="password"
                    id="fePassword"
                    placeholder="Password"
                    value=""
                    onChange={() => {}}
                    autoComplete="current-password"
                  />
                </Col>
                {/* Last Name */}

              </Row>
              <Row form>
                {/* Email */}
                <Col md="12" className="form-group">
                  <label htmlFor="fePassword">Confirm Password
                  </label>
                  <FormInput
                    type="password"
                    id="fePasswordconfirm"
                    placeholder="Password"
                    value=""
                    onChange={() => {}}
                    autoComplete="current-password"
                  />
                </Col>
                {/* Password */}

              </Row>

              <Button theme="accent">Change password</Button>
            </Form>
          </Col>
        </Row>
      </ListGroupItem>
    </ListGroup>
  </Card>
);

ResetPassword.propTypes = {
  /**
   * The component's title.
   */
  title: PropTypes.string
};

ResetPassword.defaultProps = {
  title: "Password ändern"
};

export default ResetPassword;
