import React from "react";
import { Link } from "react-router-dom";
import {
  Container,
  Row,
  Col,
  Card,
  CardBody,
  Form,
  FormGroup,
  FormInput,
  Button
} from "shards-react";
import {loginUser} from "../reducers/authentication/actions";
import {connect} from "react-redux";
import { withRouter } from 'react-router';
import {forgetPW} from "../reducers/authentication/actions"
import {toast} from "react-toastify";
import 'react-toastify/dist/ReactToastify.css';

class ForgotPassword extends React.Component {


  constructor(props) {
    super(props);

    this.state = {
      email: "",
    }
  }

  onReset() {
    this.props.forgotPassword(this.state.email, (success) => {
      toast.success("You will receive an email with a unique token.")
      this.props.history.goBack();
    }, (error) => {
      toast.error("An error occured.")
    })
  }

  render() {
    return (
      <Container fluid className="main-content-container h-100 px-4">
        <Row noGutters className="h-100">
          <Col lg="3" md="5" className="auth-form mx-auto my-auto">
            <Card>
              <CardBody>
                {/* Logo */}
                <img
                  className="auth-form__logo d-table mx-auto mb-3"
                  src={require("../assets/images/logo_wide.png")}
                  style={{maxWidth: '9rem'}}
                  alt="Shards Dashboards - Login Template"
                />

                {/* Title */}
                <h5 className="auth-form__title text-center mb-4">
                  Reset Password
                </h5>

                {/* Form Fields */}
                <Form>
                  <FormGroup>
                    <label htmlFor="exampleInputEmail1">Email address</label>
                    <FormInput
                      value={this.state.email}
                      onChange={(e) => {this.setState({email: e.target.value})}}
                      type="email"
                      id="exampleInputEmail1"
                      placeholder="Enter email"
                      autoComplete="email"
                    />
                    <small className="form-text text-muted text-center">
                      You will receive an email with a unique token.
                    </small>
                  </FormGroup>
                  <Button
                    pill
                    theme="accent"
                    className="d-table mx-auto"
                    type="submit"
                    onClick={this.onReset.bind(this)}
                  >
                    Reset Password
                  </Button>
                </Form>
              </CardBody>
            </Card>

            {/* Meta Details */}
            <div className="auth-form__meta d-flex mt-4">
              <Link to="/login" className="mx-auto">
                Take me back to login.
              </Link>
            </div>
          </Col>
        </Row>
      </Container>
    )
  }
}



const mapStateToProps = state => ({
  authenticated: state.authentication.authenticated,
});

const mapDispatchToProps = dispatch => ({
  forgotPassword: (email, successCb, errorCb) => dispatch(forgetPW(email, successCb, errorCb)),
});
export default withRouter(connect(mapStateToProps, mapDispatchToProps)(ForgotPassword));

