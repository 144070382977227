/* eslint-disable no-unused-vars */

import React, { PureComponent, Fragment } from 'react';
import PropTypes from 'prop-types';

import { connect } from 'react-redux';
import { withRouter } from 'react-router';
import Https from "../services/api/apiClient";

const mapStateToProps = state => ({
  authenticated: state.authentication.authenticated,
  accessToken: state.authentication.accessToken
});

const mapDispatchToProps = dispatch => ({
});

/**
 * ProtectedRoutes
 */
class ProtectedRoutes extends PureComponent {
  static propTypes = {
    authenticated: PropTypes.bool.isRequired,
    accessToken: PropTypes.string.isRequired,
    history: PropTypes.shape({
      push: PropTypes.func.isRequired,
    }).isRequired,
    children: PropTypes.node,
    redirectTo: PropTypes.string,
  }

  static defaultProps = {
    authenticated: false,
    children: null,
    redirectTo: '/login',
  }

  componentWillReceiveProps(nextProps) {
    if (nextProps.authenticated === false) {
      this.props.history.push(this.props.redirectTo);
    }
  }

  componentWillMount() {
    if (this.props.authenticated === false) {
      this.props.history.push(this.props.redirectTo);
    } else {
      console.log("componentDidMount", this.props.accessToken);
        const token = this.props.accessToken;
        Https.setAccessToken(token);
        // apiClient.defaults.headers.common['Authorization'] = "Bearer " +token;
        // apiClient.interceptors.request.use(function (config) {
        //
        //     config.headers.Authorization =  token;
        //
        //     return config;
        // });
    }
  }

  render() {
    return (<Fragment>{this.props.children}</Fragment>);
  }
}

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(ProtectedRoutes));
