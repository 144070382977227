import React, { Fragment } from 'react';
import ReactDOM from 'react-dom';
import { Provider } from 'react-redux';
import { HashRouter as Router, Switch, Route } from 'react-router-dom'
import { PersistGate } from 'redux-persist/integration/react'

import configureStore from './store';

import { UnprotectedRoutes, ProtectedRoutes, Logout } from './authentication';
import {toast, ToastContainer} from "react-toastify";

import unprotectedRoutes from "./unprotectedRoutes";
import protectedRoutes from "./protectedRoutes";
import withTracker from "./withTracker";

const {store, persistor} = configureStore();

toast.configure({
  position: toast.POSITION.TOP_RIGHT,
  autoClose: 3000
});


export default () => (
  <Provider store={store}>
    <PersistGate persistor={persistor}>
      <Router>
        <UnprotectedRoutes redirectTo="/">
          <Switch>
            {unprotectedRoutes.map((route, index) => {
              return (
                <Route
                  key={index}
                  path={route.path}
                  exact={route.exact}
                  component={withTracker(props => {
                    return (
                      <route.layout {...props}>
                        <route.component {...props} />
                      </route.layout>
                    );
                  })}
                />
              );
            })}

            <ProtectedRoutes redirectTo="/login">
              {protectedRoutes.map((route, index) => {
                return (
                  <Route
                    key={index}
                    path={route.path}
                    exact={route.exact}
                    component={withTracker(props => {
                      return (
                        <route.layout {...props}>
                          <route.component {...props} />
                        </route.layout>
                      );
                    })}
                  />
                );
              })}

              <ToastContainer key={'toasts'} />
            </ProtectedRoutes>
          </Switch>
        </UnprotectedRoutes>
      </Router>
    </PersistGate>
  </Provider>
);
