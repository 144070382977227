import React from "react";
import { Container, Row, Col } from "shards-react";

import PageTitle from "../components/common/PageTitle";
import UserAccountDetails from "../components/user-profile-soflow/UserAccountDetails";
import ResetPassword from "../components/user-profile-soflow/ResetPassword";
import ApiKey from "../components/user-profile-soflow/ApiKey";
import {connect} from "react-redux";
import { withRouter } from 'react-router';


class UserProfileSoFlow extends React.Component {

  hasApiAccess() {
    try {
      return this.props.user.permissions.api;
    } catch(err) {
      return false;
    }
  }

  getApiKey() {
      try {
        return this.props.user.api.key;
      } catch(err) {
        return "No API Key Available";
      }
  }

  getApiCallbacks() {
    try {
      return this.props.user.api.callbacks;
    } catch(err) {
      return [];
    }
  }

  isApiActive() {
    try {
      return this.props.user.api.active;
    } catch(err) {
      return false;
    }
  }




  render() {
    return (
      <Container fluid className="main-content-container px-4">
        <Row noGutters className="page-header py-4">
          <PageTitle title="User Profile" subtitle="Overview" md="12" className="ml-sm-auto mr-sm-auto" />
        </Row>
        <Row>
          <Col lg="6">
            <UserAccountDetails user={this.props.user} />
          </Col>
          <Col lg="6">
            {/*<ResetPassword/>*/}
          </Col>
        </Row>
        <Row>
          <Col lg="6">
            {this.hasApiAccess() ?  <ApiKey active={this.isApiActive()} callbacks={this.getApiCallbacks()} apiKey={this.getApiKey()} hasAccess={this.hasApiAccess()} /> : null }
          </Col>
        </Row>
      </Container>
    )
  }
}

const mapStateToProps = state => ({
  user: state.authentication.user,
});

const mapDispatchToProps = dispatch => ({

});
export default withRouter(connect(mapStateToProps, mapDispatchToProps)(UserProfileSoFlow));

