import React from "react";
import PropTypes from "prop-types";
import Select from 'react-select';



const CustomerSelect = ({allUsers, onChange, defaultElement}) => {

  var defaultVal =  defaultElement? defaultElement : "No User";
  var userSelectOptions =  [{label: defaultVal, value: {_id: -1}}];
  for(var i = 0; i < allUsers.length; i++) {
    var companyString = allUsers[i] && allUsers[i].company ? " - " + allUsers[i].company : "";
    userSelectOptions.push({value: allUsers[i], label: allUsers[i].forename + " "+ allUsers[i].surname + companyString})
  }


  return (
    <Select
      components={{ ClearIndicator }}
      className={"customer-select"}
      styles={{ clearIndicator: ClearIndicatorStyles }}
      onChange={(e) => {onChange("user", e.value)}}
      closeMenuOnSelect={true}
      options={userSelectOptions}
    />
  )
};

export default CustomerSelect;

CustomerSelect.propTypes = {
  /**
   * The page title.
   */


};


const CustomClearText = () => 'clear all';
const ClearIndicator = (props) => {
  const { children = <CustomClearText/>, getStyles, innerProps: { ref, ...restInnerProps } } = props;
  return (
    <div {...restInnerProps} ref={ref} style={getStyles('clearIndicator', props)}>
      <div style={{ padding: '0px 5px' }}>
        {children}
      </div>
    </div>
  );
};

const ClearIndicatorStyles = (base, state) => ({
  ...base,
  cursor: 'pointer',
  color: state.isFocused ? 'blue' : 'black',
});

